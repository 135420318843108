// VideoControls.js
import React from "react";
import { Group, Button, Slider, Tooltip } from "@mantine/core";
import { IconPlayerPlay, IconPlayerPause } from "@tabler/icons-react";

const VideoControls = ({
  isPlaying,
  togglePlayPause,
  currentFrame,
  totalFrames,
  handleFrameSelection,
}) => {
  const formattedCurrentFrame = currentFrame + 1;

  return (
    <Group align="center" spacing="md">
      <Button
        variant="outline"
        color="blue"
        onClick={togglePlayPause}
        size="sm"
      >
        {isPlaying ? <IconPlayerPause size={16} /> : <IconPlayerPlay size={16} />}
      </Button>

      <Slider
        value={currentFrame}
        min={0}
        max={totalFrames - 1}
        onChange={(value) => handleFrameSelection({ target: { value } })}
        style={{ flexGrow: 1 }}
        size="sm"
      />

      <Tooltip label={`${formattedCurrentFrame} / ${totalFrames}`}>
        <Button variant="subtle" size="sm" disabled>
          {formattedCurrentFrame} / {totalFrames}
        </Button>
      </Tooltip>
    </Group>
  );
};

export default VideoControls;