import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import MainLogin from './Login';
import SignIn from 'components/Login/SignIn';
import Register from 'components/Login/Register';
import ForgotPassword from 'components/Login/ForgotPassword';

const LoginMail = () => {
  return (
    <Routes>
      <Route index element={<MainLogin />} />
      <Route path="sign-in" element={<SignIn />} />
      <Route path="register" element={<Register />} />
      <Route path="forgot-password" element={<ForgotPassword />} />
      <Route
        path="/register/confirm-account/:confirmToken"
        element={<Register />}
      />
      <Route
        path="/forgot-password/reset-password/:resetToken"
        element={<ForgotPassword />}
      />
      <Route path="*" element={<Navigate to="/login-mail" replace />} />
    </Routes>
  );
};

export default LoginMail;