import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Container, Group, Text, Stack, Alert } from '@mantine/core';
import { AlertCircle } from 'lucide-react';
import { useAgentAttachment } from 'hooks/DirectAgentAttach/useAgentAttachment';
import CurrentCompetition from 'components/CurrentCompetition';
import DeleteAttach from 'components/DirectAgentAttach/DeleteAttach';
import AgentUpload from 'components/DirectAgentAttach/Upload/AgentUpload';
import AgentDeploy from 'components/DirectAgentAttach/Deploy/AgentDeploy';
import AgentMonitor from 'components/DirectAgentAttach/Monitor/AgentMonitor';
import ProgressSteps from 'components/DirectAgentAttach/ProgressSteps';
import AgentNavigation from 'components/DirectAgentAttach/AgentNavigation';

// Helper function to determine initial page based on status
const getInitialPage = (status) => {
  if (['active', 'paused'].includes(status)) return 'monitor';
  if (['created', 'uploading', 'upload_failed', 'upload_validated'].includes(status)) return 'upload';
  if (['deploying', 'deploy_queue', 'deploy_run', 'deploy_failed'].includes(status)) return 'deploy';
  return 'upload'; // fallback
};

const AgentAttached = () => {
  const { competitionId, attacheAgentId } = useParams();
  const {
    attachment,
    isFetching,
    error,
    fetchAttachment,
    handleDeleteAttach
  } = useAgentAttachment(competitionId, attacheAgentId);

  // Initialize currentPage based on status
  const [currentPage, setCurrentPage] = useState('upload');

  // Update current page when attachment status changes
  useEffect(() => {
    if (attachment?.status) {
      setCurrentPage(getInitialPage(attachment.status));
    }
  }, [attachment?.status]);

  // Loading state
  if (isFetching || !attachment) {
    return (
      <Container size="lg" className="py-8">
        <Text>Loading...</Text>
      </Container>
    );
  }

  // Render content based on current page
  const renderPageContent = () => {
    switch (currentPage) {
      case 'deploy':
        return (
          <AgentDeploy
            competitionId={competitionId}
            attacheAgentId={attacheAgentId}
            status={attachment.status}
            statusMessage={attachment.last_status_message}
            queueInfo={attachment.queue_info}
            runInfo= {attachment.run_info}
            onStatusChange={fetchAttachment}
          />
        );
      case 'monitor':
        return (
          <AgentMonitor 
            competitionId={competitionId}
            attacheAgentId={attacheAgentId}
            status={attachment.status}
            statusMessage={attachment.last_status_message}
            onStatusChange={fetchAttachment} 
          />
        );
      default:
        return (
          <AgentUpload
            competitionId={competitionId}
            attacheAgentId={attacheAgentId}
            status={attachment.status}
            statusMessage={attachment.last_status_message}
            onStatusChange={fetchAttachment}
          />
        );
    }
  };

  return (
    <Container size="lg" className="py-8">
      <CurrentCompetition competitionId={competitionId} />
      
      <Stack className="gap-6">
        {/* Header */}
        <Group position="apart" align="center">
          <Text size="xl" fw={700}>
            {attachment.name || 'Agent Configuration'}
          </Text>
          <DeleteAttach onDelete={handleDeleteAttach} />
        </Group>

        {(attachment.status != 'active') &&
        <ProgressSteps status={attachment.status} />
        }
        {/* Error Alert */}
        {error && (
          <Alert icon={<AlertCircle size={16} />} color="red" title="Error">
            {error}
          </Alert>
        )}
        <AgentNavigation 
          status={attachment.status}
          currentPage={currentPage}
          onPageChange={setCurrentPage}
        />
        {/* Main Content */}
        {renderPageContent()}

        <AgentNavigation 
          status={attachment.status}
          currentPage={currentPage}
          onPageChange={setCurrentPage}
        />

      </Stack>
    </Container>
  );
};

export default AgentAttached;