import React, { useState, useEffect } from 'react';
import { Stack, Card, Group, Text, Progress, Alert, Badge, Box, List, Button, Collapse } from '@mantine/core';
import { AlertCircle, Check, X, ChevronDown, ChevronRight } from 'lucide-react';
import FileManagement from 'components/DirectAgentAttach/Upload/FileManagement';
import RuntimeSelector from 'components/DirectAgentAttach/RuntimeSelector';
import { Editor } from '@monaco-editor/react';

// Instructions component
const AgentInstructions = () => {
  const [showStructure, setShowStructure] = useState(true);
  
  const editorOptions = {
    minimap: { enabled: false },
    scrollBeyondLastLine: false,
    lineNumbers: 'on',
    fontSize: 14,
    tabSize: 4,
  };

  return (
    <Card withBorder>
      <Group position="apart" mb="md" style={{ cursor: 'pointer' }} onClick={() => setShowStructure(!showStructure)}>
        <Text weight={500}>Implementation Guide</Text>
        <Button 
          variant="subtle" 
          compact
          rightIcon={showStructure ? <ChevronDown size={16} /> : <ChevronRight size={16} />}
        >
          {showStructure ? 'Hide Details' : 'Show Details'}
        </Button>
      </Group>

      <Collapse in={showStructure}>
        <Box mb="md">
          <Text size="sm" mb="xs" color="dimmed">Required Structure:</Text>
          <List size="sm" spacing="xs">
            <List.Item>Create a file named <code>agent.py</code> with the <code>Agent</code> class</List.Item>
            <List.Item>Include the model.zip file (e.g., Stable Baselines3 PPO)</List.Item>
            {/* TODO <List.Item>The <code>choose_action</code> method should handle observations of shape: "[1,2,3,4]"</List.Item> */}
          </List>
        </Box>
        
        <Text size="sm" mb="xs" color="dimmed">Example Implementation:</Text>
        <Editor
          height="200px"
          language="python"
          theme="vs-dark"
          value={`class Agent:
    def __init__(self, env):
        self.env = env
        # from stable_baselines3 import DQN
        # self.model = DQN.load("model.zip")

    def choose_action(self, observation, reward=0.0, terminated=False, truncated=False, info={}, action_mask=None):
        # action, _ = self.model.predict(observation, deterministic=True)
        return action`}
          options={editorOptions}
        />
      </Collapse>
    </Card>
  );
};

export default AgentInstructions;