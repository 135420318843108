import React from 'react';
import { useParams } from 'react-router-dom';
import { Container, Loader, Alert, Stack } from '@mantine/core';
import { AlertCircle } from 'lucide-react';
import useCompetitionData from 'hooks/competition/useCompetitionData';
import useConfigurationData from 'hooks/competition/useConfigurationData';
import useEnvironmentData from 'hooks/competition/useEnvironmentData';
import CompetitionSection from './sections/CompetitionSection';
import ConfigurationSection from './sections/ConfigurationSection';
import EnvironmentSection from './sections/EnvironmentSection';
import OverviewSection from './sections/OverviewSection';

const EditCompetition = () => {
  const { competitionId } = useParams();

  // Competition data management
  const {
    competitionData,
    competitionLoading,
    competitionError,
    updateCompetition,
    configurationId,
    environmentId,
  } = useCompetitionData(competitionId);

  // Configuration data management
  const {
    configurationData,
    configurationLoading,
    configurationError,
    updateConfiguration,
    handleSaveConfiguration,
    handleDeleteConfiguration,
  } = useConfigurationData(competitionId, configurationId);

  // Environment data management
  const {
    environmentData,
    environmentLoading,
    environmentError,
    updateEnvironment,
    handleSaveEnvironment,
    handleDeleteEnvironment
  } = useEnvironmentData(competitionId, environmentId);

  // Aggregate loading and error states
  const isLoading = competitionLoading || configurationLoading || environmentLoading;
  const hasError = competitionError || configurationError || environmentError;
  const errorMessage = hasError instanceof Error ? hasError.message : String(hasError);

  if (isLoading) {
    return (
      <Container size="md" className="h-screen flex items-center justify-center">
        <Loader size="xl" variant="bars" />
      </Container>
    );
  }

  if (hasError) {
    return (
      <Container size="md" className="mt-8">
        <Alert 
          icon={<AlertCircle size={16} />}
          title="Error"
          color="red"
          variant="filled"
        >
          {errorMessage}
        </Alert>
      </Container>
    );
  }


  return (
    <Container size="md" className="py-8">
      <Stack spacing="xl">
        <CompetitionSection 
          competitionId={competitionId}
          competitionData={competitionData}
          updateCompetition={updateCompetition}
        />

        {competitionId && (
          <>
            <OverviewSection 
              competitionId={competitionId} 
            />

            <ConfigurationSection
              configurationId={configurationId}
              configurationData={configurationData}
              updateConfigurationData={updateConfiguration}
              handleSaveConfiguration={handleSaveConfiguration}
              handleDeleteConfiguration={handleDeleteConfiguration}
              loading={configurationLoading}
            />

            {configurationId && (
              <EnvironmentSection
                environmentId={environmentId}
                environmentData={environmentData}
                updateEnvironment={updateEnvironment}
                handleSaveEnvironment={handleSaveEnvironment}
                handleDeleteEnvironment={handleDeleteEnvironment}
                loading={environmentLoading}
              />
            )}
          </>
        )}
      </Stack>
    </Container>
  );
};

export default EditCompetition;