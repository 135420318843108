// frontend/src/hooks/useImageMiniature.js
import { useState, useCallback } from "react";
import axios from "axios";

const useImageMiniature = (competitionId) => {
  const [image, setImage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const fetchImage = useCallback(async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        `/api/competition_asset/${competitionId}/image/miniature`,
        {
          responseType: "blob", // Important for directly handling binary data
        }
      );
      setImage(URL.createObjectURL(response.data)); // Create a URL for the blob
      setError(null);
    } catch (err) {
      setError(err.response?.data?.error || "Failed to fetch image");
      setImage(null);
    } finally {
      setLoading(false);
    }
  }, [competitionId]);

  const uploadImage = async (file) => {
    setLoading(true);
    const formData = new FormData();
    formData.append("file", file);
    try {
      await axios.post(
        `/api/competition_asset/${competitionId}/image/miniature`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      fetchImage(); // Refetch the image to update the UI
      setError(null);
    } catch (err) {
      setError(err.response?.data?.error || "Failed to upload image");
    } finally {
      setLoading(false);
    }
  };

  const deleteImage = async () => {
    setLoading(true);
    try {
      await axios.delete(
        `/api/competition_asset/${competitionId}/image/miniature`
      );
      setImage(null); // Remove the image from the UI
      setError(null);
    } catch (err) {
      setError(err.response?.data?.error || "Failed to delete image");
    } finally {
      setLoading(false);
    }
  };

  return {
    image,
    loading,
    error,
    fetchImage,
    uploadImage,
    deleteImage,
  };
};

export default useImageMiniature;
