import React, { useState, useMemo, useEffect } from 'react';
import { Paper, Select, Code, Tooltip, ActionIcon, Box, Stack } from '@mantine/core';
import { Copy } from 'lucide-react';
import { useClipboard } from '@mantine/hooks';
import { IconChevronDown, IconChevronUp } from '@tabler/icons-react';
import { Collapse, Button, Group, Text } from '@mantine/core';

const RuntimeSelector = ({ 
  runtimeOptions = {}, 
  setSelectedImage
}) => {
  const clipboard = useClipboard({ timeout: 500 });
  const [isExpanded, setIsExpanded] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState(null);
  const [selectedVersion, setSelectedVersion] = useState(null);
  const [selectedFramework, setSelectedFramework] = useState(null);
  const [selectedFrameworkVersion, setSelectedFrameworkVersion] = useState(null);

  // Compute available options for each dropdown
  const languageOptions = useMemo(() => 
    Object.keys(runtimeOptions).map(lang => ({
      value: lang,
      label: lang.charAt(0).toUpperCase() + lang.slice(1)
    })), [runtimeOptions]);

  const versionOptions = useMemo(() => 
    selectedLanguage ? 
      Object.keys(runtimeOptions[selectedLanguage]).map(version => ({
        value: version,
        label: version
      })) : [], [runtimeOptions, selectedLanguage]);

  const frameworkOptions = useMemo(() => 
    selectedVersion ? 
      Object.keys(runtimeOptions[selectedLanguage][selectedVersion]).map(framework => ({
        value: framework,
        label: framework === 'no_framework' ? 'No Framework' : framework
      })) : [], [runtimeOptions, selectedLanguage, selectedVersion]);

  const frameworkVersionOptions = useMemo(() => 
    selectedFramework ?
      Object.keys(runtimeOptions[selectedLanguage][selectedVersion][selectedFramework]).map(version => ({
        value: version,
        label: version === 'no_framework' ? 'None' : version
      })) : [], [runtimeOptions, selectedLanguage, selectedVersion, selectedFramework]);

  // Get current requirements text
  const requirementsTxt = useMemo(() => {
    if (selectedLanguage && selectedVersion && selectedFramework && selectedFrameworkVersion) {
      return runtimeOptions[selectedLanguage][selectedVersion][selectedFramework][selectedFrameworkVersion].requirement || '';
    }
    return '';
  }, [runtimeOptions, selectedLanguage, selectedVersion, selectedFramework, selectedFrameworkVersion]);

  // Auto-select first available options on component mount
  useEffect(() => {
    if (Object.keys(runtimeOptions).length > 0 && !selectedLanguage) {
      const language = Object.keys(runtimeOptions)[0];
      const version = Object.keys(runtimeOptions[language])[0];
      const framework = Object.keys(runtimeOptions[language][version])[0];
      const frameworkVersion = Object.keys(runtimeOptions[language][version][framework])[0];
      
      setSelectedLanguage(language);
      setSelectedVersion(version);
      setSelectedFramework(framework);
      setSelectedFrameworkVersion(frameworkVersion);
      
      const imageId = runtimeOptions[language][version][framework][frameworkVersion].id;
      setSelectedImage(imageId);
    }
  }, [runtimeOptions, setSelectedImage]);

  const handleLanguageChange = (value) => {
    if (!value) return;
    setSelectedLanguage(value);
    
    const firstVersion = Object.keys(runtimeOptions[value])[0];
    setSelectedVersion(firstVersion);
    
    const firstFramework = Object.keys(runtimeOptions[value][firstVersion])[0];
    setSelectedFramework(firstFramework);
    
    const firstFrameworkVersion = Object.keys(runtimeOptions[value][firstVersion][firstFramework])[0];
    setSelectedFrameworkVersion(firstFrameworkVersion);
    
    const imageId = runtimeOptions[value][firstVersion][firstFramework][firstFrameworkVersion].id;
    setSelectedImage(imageId);
  };

  const handleVersionChange = (value) => {
    if (!value || !selectedLanguage) return;
    setSelectedVersion(value);
    
    const firstFramework = Object.keys(runtimeOptions[selectedLanguage][value])[0];
    setSelectedFramework(firstFramework);
    
    const firstFrameworkVersion = Object.keys(runtimeOptions[selectedLanguage][value][firstFramework])[0];
    setSelectedFrameworkVersion(firstFrameworkVersion);
    
    const imageId = runtimeOptions[selectedLanguage][value][firstFramework][firstFrameworkVersion].id;
    setSelectedImage(imageId);
  };

  const handleFrameworkChange = (value) => {
    if (!value || !selectedLanguage || !selectedVersion) return;
    setSelectedFramework(value);
    
    const firstFrameworkVersion = Object.keys(runtimeOptions[selectedLanguage][selectedVersion][value])[0];
    setSelectedFrameworkVersion(firstFrameworkVersion);
    
    const imageId = runtimeOptions[selectedLanguage][selectedVersion][value][firstFrameworkVersion].id;
    setSelectedImage(imageId);
  };

  const handleFrameworkVersionChange = (value) => {
    if (!value || !selectedLanguage || !selectedVersion || !selectedFramework) return;
    setSelectedFrameworkVersion(value);
    
    const imageId = runtimeOptions[selectedLanguage][selectedVersion][selectedFramework][value].id;
    setSelectedImage(imageId);
  };

  return (
    <Paper shadow="xs" p="md">
      <Stack spacing="xs">
        <Group spacing="xs" align="center">
          <Select
            size="xs"
            label="Language"
            placeholder="Select"
            data={languageOptions}
            value={selectedLanguage}
            onChange={handleLanguageChange}
            required
            style={{ width: '120px' }}
          />
          <Select
            size="xs"
            label="Version"
            placeholder="Select"
            data={versionOptions}
            value={selectedVersion}
            onChange={handleVersionChange}
            disabled={!selectedLanguage}
            required
            style={{ width: '100px' }}
          />
          <Text size="sm" color="dimmed">|</Text>
          <Select
            size="xs"
            label="Framework"
            placeholder="Select"
            data={frameworkOptions}
            value={selectedFramework}
            onChange={handleFrameworkChange}
            disabled={!selectedVersion}
            required
            style={{ width: '160px' }}
          />
          <Select
            size="xs"
            label="Version"
            placeholder="Select"
            data={frameworkVersionOptions}
            value={selectedFrameworkVersion}
            onChange={handleFrameworkVersionChange}
            disabled={!selectedFramework}
            required
            style={{ width: '100px' }}
          />
        </Group>
        {requirementsTxt && (
          <>
            <Button 
              variant="subtle" 
              compact 
              onClick={() => setIsExpanded(!isExpanded)}
              rightIcon={isExpanded ? <IconChevronUp size={16} /> : <IconChevronDown size={16} />}
            >
              requirements.txt
            </Button>
            <Collapse in={isExpanded}>
              <Box pos="relative">
                <Code block p="md" style={{ paddingRight: '40px' }}>
                  {requirementsTxt}
                </Code>
                <Tooltip
                  label={clipboard.copied ? "Copied!" : "Copy requirements.txt"}
                  position="left"
                >
                  <ActionIcon
                    variant="filled"
                    color="blue"
                    size="lg"
                    style={{
                      position: 'absolute',
                      top: '8px',
                      right: '8px',
                      zIndex: 1,
                    }}
                    onClick={() => clipboard.copy(requirementsTxt)}
                  >
                    <Copy size={18} />
                  </ActionIcon>
                </Tooltip>
              </Box>
            </Collapse>
          </>
        )}
      </Stack>
    </Paper>
  );
};

export default RuntimeSelector;