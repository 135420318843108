import React, { useState, useEffect } from 'react';
import { Paper, Text, Group, Badge, Box, ScrollArea, Tooltip } from '@mantine/core';
import { useInterval } from '@mantine/hooks';
import { Clock4, Bot, Trophy, Minus  } from 'lucide-react';
import { formatDistanceToNow, parseISO, isFuture,  } from 'date-fns';

const SimulationBanner = ({ competitionId }) => {
  const [simulationData, setSimulationData] = useState({ 
    evaluation_type: '', 
    nb_agent: 0, 
    results: [] 
  });

  const fetchSimulations = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_PREFIX_BACKEND}/api/simulation_attached_result/${competitionId}`);
      const data = await response.json();
      setSimulationData(data);
    } catch (error) {
      console.error('Error fetching simulation data:', error);
    }
  };

  useEffect(() => {
    fetchSimulations();
  }, [competitionId]);

  useInterval(fetchSimulations, 15000);

  const formatRelativeTime = (dateString) => {
    if (!dateString) return 'N/A';
    const date = parseISO(dateString);
    return isFuture(date) ? `in ${formatDistanceToNow(date)}` : `${formatDistanceToNow(date)} ago`;
  };

  const getStatusColor = (status) => {
    const statusColors = {
      completed: 'green',
      running: 'blue',
      error: 'red'
    };
    return statusColors[status] || 'gray';
  };

  const formatReward = (reward) => (reward != null ? reward.toFixed(2) : 'N/A');
  const renderAgentWithOutcome = (agent) => {
    const outcomeStyles = {
      winner: { color: 'green', icon: <Trophy size={14} className="text-green-500" /> },
      loser: { color: 'red', icon: null },
      draw: { color: 'blue', icon: <Minus size={14} className="text-blue-500" /> }
    };
    
    const style = outcomeStyles[agent.game_outcome] || { color: 'gray', icon: null };
    
    return (
      <Group spacing={4} noWrap>
        {style.icon}
        <Tooltip
          label={`${agent.is_direct_attach_name} (ELO: ${agent.score_elo_before})`}
          withArrow
        >
          <Text 
            size="sm" 
            weight={500}
            color={style.color}
            style={{ 
              display: 'flex', 
              alignItems: 'center', 
              gap: '4px'
            }}
          >
            {agent.is_direct_attach_name}
            {agent.score_elo_delta && (
              <Text size="xs" color={style.color}>
                {agent.score_elo_delta > 0 ? `+${agent.score_elo_delta}` : agent.score_elo_delta}
              </Text>
            )}
          </Text>
        </Tooltip>
      </Group>
    );
  };

  const renderEloGame = (sim) => {
    const isDraw = sim.agent_infos.every(agent => agent.game_outcome === 'draw');
    
    return (
      <Group position="apart" noWrap spacing={4}>
        <Group spacing={4}>
          <Text color="dimmed" size="xs">{formatRelativeTime(sim.created_at_ts)}</Text>
          <Badge color={getStatusColor(sim.status)} size="xs">
            {sim.status}
          </Badge>
        </Group>
        
        {sim.status === 'completed' && (
          <Group spacing={8}>
            {isDraw ? (
              <>
                <Badge color="blue" size="sm">Draw</Badge>
                <Group spacing={4}>
                  {sim.agent_infos.map((agent, index) => renderAgentWithOutcome(agent))}
                </Group>
              </>
            ) : (
              <Group spacing={4}>
                {/* Sort agents to show winner first */}
                {[...sim.agent_infos]
                  .sort((a, b) => (a.game_outcome === 'winner' ? -1 : 1))
                  .map((agent, index) => (
                    <React.Fragment key={index}>
                      {index > 0 && <Text color="dimmed">vs</Text>}
                      {renderAgentWithOutcome(agent)}
                    </React.Fragment>
                  ))
                }
              </Group>
            )}
          </Group>
        )}
      </Group>
    );
  };

  const renderSinglePlayerGame = (sim) => (
    <Group position="apart" noWrap spacing={4}>
      <Group spacing={4}>
        <Text color="dimmed" size="xs">{formatRelativeTime(sim.created_at_ts)}</Text>
        <Badge color={getStatusColor(sim.status)} size="xs">
          {sim.status}
        </Badge>
      </Group>
      
      {sim.agent_infos.map((agent, index) => (
        <Group key={index} spacing={4}>
          <Tooltip label={agent.is_direct_attach_name} withArrow>
            <Text size="sm" className="font-medium">
              {agent.is_direct_attach_name || 'Agent'}
            </Text>
          </Tooltip>
          {sim.status === 'completed' && (
            <Text 
              size="sm"
              weight={500}
              color={parseFloat(agent.agent_reward) >= 0 ? 'green' : 'red'}
            >
              {formatReward(agent.agent_reward)}
            </Text>
          )}
        </Group>
      ))}
    </Group>
  );

  return (
    <Paper shadow="xs" p="xs" className="w-full">
      <ScrollArea>
        <Box style={{ display: 'flex', gap: '16px', padding: '4px 0' }}>
          {simulationData.results.map((sim) => (
            <Box
              key={sim.simulation_id}
              style={{
                minWidth: 'fit-content',
                padding: '4px 12px',
                borderLeft: '3px solid',
                borderLeftColor: getStatusColor(sim.status),
                backgroundColor: 'white'
              }}
            >
              {simulationData.evaluation_type === 'elo_score' 
                ? renderEloGame(sim) 
                : renderSinglePlayerGame(sim)}
            </Box>
          ))}
        </Box>
      </ScrollArea>
    </Paper>
  );
};

export default SimulationBanner;