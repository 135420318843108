import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Card, Button, Stack, Group, TextInput, Textarea, Select, Box } from '@mantine/core';
import { Editor } from '@monaco-editor/react';
import { notifications } from '@mantine/notifications';
import axios from 'axios';
import EnvironmentFields from "components/Repository/EnvironmentFields"


const RepositoryManager = ({ basePath }) => {
  const navigate = useNavigate();
  const { objectId } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState({
    name: '',
    description: '',
    fileContent: '',
    commits: [],
    selectedCommit: '',
  });

  // Fetch repository data
  useEffect(() => {
    if (objectId) {
      fetchRepositoryData();
    }
  }, [objectId]);

  const fetchRepositoryData = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_PREFIX_BACKEND}/api/repositories/${basePath}/${objectId}`);
      setData(prev => ({
        ...prev,
        name: response.data.name || '',
        description: response.data.description || '',
        fileContent: response.data.file_content || '',
        commits: response.data.commits || [],
        selectedCommit: ''
      }));
    } catch (error) {
      notifications.show({
        title: 'Error',
        message: 'Failed to fetch repository data',
        color: 'red'
      });
    }
  };

  // Handle commit selection
  const handleCommitSelect = async (commitHash) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_PREFIX_BACKEND}/api/repositories/${basePath}/${objectId}?commit=${commitHash}`
      );
      setData(prev => ({
        ...prev,
        fileContent: response.data.file_content,
        selectedCommit: commitHash
      }));
    } catch (error) {
      notifications.show({
        title: 'Error',
        message: 'Failed to fetch commit data',
        color: 'red'
      });
    }
  };

  // Handle template loading
  const handleLoadTemplate = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_PREFIX_BACKEND}/api/templates/${basePath}`);
      setData(prev => ({
        ...prev,
        fileContent: response.data.file_content
      }));
      notifications.show({
        title: 'Success',
        message: 'Template loaded successfully',
        color: 'green'
      });
    } catch (error) {
      notifications.show({
        title: 'Error',
        message: 'Failed to load template',
        color: 'red'
      });
    }
  };

  // Handle save
  const handleSave = async () => {
    setIsLoading(true);
    try {
      const method = objectId ? 'put' : 'post';
      const response = await axios[method](
        `${process.env.REACT_APP_PREFIX_BACKEND}/api/repositories/${basePath}/${objectId || ''}`,
        {
          name: data.name,
          description: data.description,
          file_content: data.fileContent
        }
      );

      notifications.show({
        title: 'Success',
        message: 'Repository saved successfully',
        color: 'green'
      });

      if (!objectId && response.data.id) {
        navigate(`/edit${basePath}/${response.data.id}`);
      }
    } catch (error) {
      notifications.show({
        title: 'Error',
        message: 'Failed to save repository',
        color: 'red'
      });
    } finally {
      setIsLoading(false);
    }
  };

  // Handle delete
  const handleDelete = async () => {
    if (!window.confirm('Are you sure you want to delete this repository?')) return;

    try {
      await axios.delete(`${process.env.REACT_APP_PREFIX_BACKEND}/api/repositories/${basePath}/${objectId}`);
      notifications.show({
        title: 'Success',
        message: 'Repository deleted successfully',
        color: 'green'
      });
      navigate(`/list${basePath}`);
    } catch (error) {
      notifications.show({
        title: 'Error',
        message: 'Failed to delete repository',
        color: 'red'
      });
    }
  };

  return (
    <Card shadow="sm" padding="lg" radius="md" withBorder>
      <Stack spacing="md">
        <TextInput
          label="Name"
          value={data.name}
          onChange={(e) => setData(prev => ({ ...prev, name: e.target.value }))}
          disabled={!!objectId}
          required
        />

        <Textarea
          label="Description"
          value={data.description}
          onChange={(e) => setData(prev => ({ ...prev, description: e.target.value }))}
          minRows={2}
        />

        {basePath === 'environment' && <EnvironmentFields objectId={objectId} />}

        <Box h={400}>
          <Editor
            height="100%"
            value={data.fileContent}
            onChange={(value) => setData(prev => ({ ...prev, fileContent: value }))}
            language="python"
            theme="vs-dark"
            options={{
              minimap: { enabled: false },
              scrollBeyondLastLine: false,
              fontSize: 14,
            }}
          />
        </Box>

        <Group position="apart">
          <Button onClick={handleLoadTemplate} variant="outline">
            Load Template
          </Button>
          
          {objectId && data.commits.length > 0 && (
            <Select
              w={300}
              placeholder="Select commit"
              value={data.selectedCommit}
              onChange={handleCommitSelect}
              data={data.commits.map(commit => ({
                value: commit.split(' ')[0],
                label: commit
              }))}
            />
          )}
        </Group>

        <Group position="apart">
          <Button onClick={handleSave} loading={isLoading} color="blue">
            {objectId ? 'Update' : 'Create'} Repository
          </Button>
          
          {objectId && (
            <Button onClick={handleDelete} color="red" variant="outline">
              Delete Repository
            </Button>
          )}
        </Group>
      </Stack>
    </Card>
  );
};

export default RepositoryManager;