import React from 'react';
import { Group, Button } from '@mantine/core';
import { ArrowLeft, Rocket } from 'lucide-react';

const AgentNavigation = ({ status, currentPage, onPageChange }) => {
  const showDeployButton = currentPage === 'upload' && ['upload_validated', 'deploying', 'deploy_queue', 'deploy_run'].includes(status);
  const showBackToUpload = currentPage === 'deploy' && ['upload_validated', 'deploying', 'deploy_queue', 'deploy_run', 'deploy_failed'].includes(status);

  // Don't render anything on monitor page
  if (currentPage === 'monitor') {
    return null;
  }

  return (
    <div className="mt-8 border-t border-gray-200 pt-6">
      <Group position="apart">
        {showBackToUpload && (
          <Button
            variant="light"
            leftIcon={<ArrowLeft size={16} />}
            onClick={() => onPageChange('upload')}
          >
            Back to Upload
          </Button>
        )}
        
        {showDeployButton && (
          <Button
            color="blue"
            leftIcon={<Rocket size={16} />}
            onClick={() => onPageChange('deploy')}
          >
            Proceed to Deployment
          </Button>
        )}
      </Group>
    </div>
  );
};

export default AgentNavigation;