import React, { useState, useEffect } from 'react';
import ReactMarkdown from 'react-markdown';
import { IconEdit, IconDeviceFloppy, IconX } from '@tabler/icons-react';
import { Button, Textarea, Paper, Stack, Group, Title, Loader } from '@mantine/core';
import useMarkdownEditor from 'hooks/competition/useMarkdownEditor';

const MarkdownEditor = ({ competitionId, isViewOnly }) => {
  const {
    markdown,
    setMarkdown,
    loading,
    error,
    fetchMarkdown,
    saveOrUpdateMarkdown,
  } = useMarkdownEditor(competitionId);

  const [editMode, setEditMode] = useState(false);

  useEffect(() => {
    fetchMarkdown();
  }, [fetchMarkdown]);

  const handleSave = async () => {
    await saveOrUpdateMarkdown(markdown);
    setEditMode(false);
  };

  const handleCancel = () => {
    fetchMarkdown();
    setEditMode(false);
  };

  if (loading) return <Loader size="xl" />;
  if (error) return <Title order={3} color="red">Error: {error}</Title>;

  return (
      <Stack spacing="md">
        {editMode ? (
          <>
            <Textarea
              value={markdown}
              onChange={(event) => setMarkdown(event.currentTarget.value)}
              minRows={10}
              autosize
              placeholder="Enter your markdown here..."
            />
            <Group position="right">
              <Button leftIcon={<IconDeviceFloppy size={16} />} onClick={handleSave} color="blue">
                Save
              </Button>
              <Button leftIcon={<IconX size={16} />} onClick={handleCancel} variant="light" color="gray">
                Cancel
              </Button>
            </Group>
          </>
        ) : (
          <>
              <ReactMarkdown>{markdown}</ReactMarkdown>
            {!isViewOnly && (
              <Group position="right">
                <Button leftIcon={<IconEdit size={16} />} onClick={() => setEditMode(true)} variant="light">
                  Edit
                </Button>
              </Group>
            )}
          </>
        )}
      </Stack>
  );
};

export default MarkdownEditor;