// frontend/src/hooks/useMarkdownEditor.js
import { useState, useCallback } from "react";
import axios from "axios";

const useMarkdownEditor = (competitionId) => {
  const [markdown, setMarkdown] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const fetchMarkdown = useCallback(async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        `/api/competition_asset/${competitionId}/markdown/overview`
      );
      setMarkdown(response.data.content);
      setError(null);
    } catch (err) {
      setError(err.message || "Failed to fetch markdown");
      setMarkdown("");
    } finally {
      setLoading(false);
    }
  }, [competitionId]);

  const saveOrUpdateMarkdown = async (content) => {
    setLoading(true);
    const method = markdown ? "put" : "post";
    try {
      await axios({
        method: method,
        url: `/api/competition_asset/${competitionId}/markdown/overview`,
        data: { content },
        headers: { "Content-Type": "application/json" },
      });
      setMarkdown(content); // Ensure the markdown state is updated with the new content
      setError(null);
    } catch (err) {
      setError(
        err.message ||
          `Failed to ${method === "put" ? "update" : "save"} markdown`
      );
    } finally {
      setLoading(false);
    }
  };

  const deleteMarkdown = async () => {
    setLoading(true);
    try {
      await axios.delete(
        `/api/competition_asset/${competitionId}/markdown/overview`
      );
      setMarkdown("");
      setError(null);
    } catch (err) {
      setError(err.message || "Failed to delete markdown");
    } finally {
      setLoading(false);
    }
  };

  return {
    markdown,
    loading,
    error,
    fetchMarkdown,
    saveOrUpdateMarkdown,
    deleteMarkdown,
    setMarkdown,
  };
};

export default useMarkdownEditor;
