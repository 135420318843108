import React, { useEffect } from "react";
import '@mantine/notifications/styles.css';
import '@mantine/core/styles.css';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  useLocation
} from "react-router-dom";

import ReactGA from "react-ga4";

import { AuthProvider, useAuth } from "./context/AuthContext";
import ListCompetition from "./pages/Competition/List";
import ViewCompetition from "./pages/Competition/View";
import EditCompetition from "./pages/Competition/Edit";
import ListAgent from "./pages/Agent/List";
import EditAgent from "./pages/Agent/Edit";
import ListEnvironment from "./pages/Environment/List";
import EditEnvironment from "./pages/Environment/Edit";
import CompanyInfo from "./pages/CompanyInfo";
import MyAgents from "./pages/MyAgents";

import Profiles from "./pages/Profiles";
import Profile from "./pages/Profile";
import Login from "./pages/Login";
import LoginMail from "./pages/LoginMail";
import Shell from "./components/Shell";
import Footer from "./components/Footer";

import AgentAttached from "./pages/AgentAttached";
import NotFound from "./pages/NotFound";
import BetaBanner from "./components/BetaBanner";
import axios from "axios";
import { MantineProvider } from '@mantine/core';
import { ModalsProvider } from '@mantine/modals';
import { Notifications } from '@mantine/notifications';

// import "./App.css"

axios.defaults.baseURL = `${process.env.REACT_APP_PREFIX_BACKEND}`;

axios.defaults.withCredentials = true;

ReactGA.initialize('G-X9HEHXRWBX'); 



function App() {
  return (
    <MantineProvider withGlobalStyles withNormalizeCSS>
           <Notifications
        position="top-right"
      />
      <ModalsProvider>
        
        <AuthProvider>
          <Router>
          <GoogleAnalyticsRouteTracker />

            <Shell>

              
              <Routes>
          <Route path="/" element={<Navigate to="/listcompetition" />} />
          <Route path="/company" element={<CompanyInfo />} />
          <Route path="/listcompetition" element={<ListCompetition />} />
          <Route
            path="/viewcompetition/:competitionId"
            element={<ViewCompetition />}
          />
          <Route path="/login" element={<Login />} />

          <Route path="/login-mail/*" element={<LoginMail />} />

          <Route
            path="/attach-agent/:competitionId/:attacheAgentId"
            element={
              <RequireAuth redirectTo="/login">
                <AgentAttached />
              </RequireAuth>
            }
          />
          <Route
            path="/my-agents"
            element={
              <RequireAuth redirectTo="/login">
                <MyAgents />
              </RequireAuth>
            }
          />
          <Route
            path="/my-profile"
            element={
              <RequireAuth redirectTo="/login">
                <Profile />
              </RequireAuth>
            }
          />

          <Route
            path="/editcompetition"
            element={
              <RequireAdmin>
                <EditCompetition />
              </RequireAdmin>
            }
          />
          <Route
            path="/editcompetition/:competitionId"
            element={
              <RequireAdmin>
                <EditCompetition />
              </RequireAdmin>
            }
          />
          <Route
            path="/listenvironment"
            element={
              <RequireAdmin>
                <ListEnvironment />
              </RequireAdmin>
            }
          />
          <Route
            path="/editenvironment/:objectId"
            element={
              <RequireAdmin>
                <EditEnvironment />
              </RequireAdmin>
            }
          />
          <Route
            path="/editenvironment"
            element={
              <RequireAdmin>
                <EditEnvironment />
              </RequireAdmin>
            }
          />
          <Route
            path="/listagent"
            element={
              <RequireAdmin>
                <ListAgent />
              </RequireAdmin>
            }
          />
          <Route
            path="/editagent/:objectId"
            element={
              <RequireAdmin>
                <EditAgent />
              </RequireAdmin>
            }
          />
          <Route
            path="/editagent"
            element={
              <RequireAdmin>
                <EditAgent />
              </RequireAdmin>
            }
          />
          <Route
            path="/profiles"
            element={
              <RequireAdmin>
                <Profiles />
              </RequireAdmin>
            }
          />
                <Route path="*" element={<NotFound />} />
              </Routes>
              <BetaBanner />
              <Footer />
            </Shell>
          </Router>
        </AuthProvider>
      </ModalsProvider>
    </MantineProvider>
  );
}

// Component to track route changes
function GoogleAnalyticsRouteTracker() {
  const location = useLocation();

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: location.pathname });
  }, [location]);

  return null;
}

function RequireAuth({ children, redirectTo }) {
  const { isAuthenticated } = useAuth();
  return isAuthenticated ? children : <Navigate to={redirectTo} />;
}

function RequireAdmin({ children }) {
  const { isAdmin, isAuthenticated } = useAuth();
  return isAuthenticated && isAdmin ? children : <Navigate to="/login" />;
}

export default App;
