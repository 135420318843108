import React from "react";
import ListPage from "components/Repository/ListPage";

const ListEnvironment = ({ objectTypeName }) => {
  return (
    <ListPage
      apiEndpoint={`/api/repositories/environment/`}
      basePath={`environment`}
    />
  );
};

export default ListEnvironment;
