// frontend/src/pages/Environment.js

import React from 'react';
import RepositoryManager from 'components/Repository/RepositoryManager';

const Environment = () => {
    return <RepositoryManager basePath="environment" />;
};

export default Environment;
