import React from 'react';
import { 
  Card, 
  Text, 
  Select, 
  Button, 
  Group, 
  Stack, 
  Modal,
  Badge
} from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { notifications } from '@mantine/notifications';
import { CheckCircle, XCircle } from 'lucide-react';

const EnvironmentSection = ({
  environmentId,
  environmentData,
  updateEnvironment,
  handleSaveEnvironment,
  handleDeleteEnvironment,
  loading
}) => {
  const [deleteModalOpened, { open: openDeleteModal, close: closeDeleteModal }] = useDisclosure(false);

  const handleSave = async () => {
    try {
      await handleSaveEnvironment();
      notifications.show({
        title: 'Success',
        message: 'Environment configuration saved successfully',
        color: 'green'
      });
    } catch (error) {
      notifications.show({
        title: 'Error',
        message: error.message || 'Failed to save environment configuration',
        color: 'red'
      });
    }
  };

  const handleDelete = async () => {
    try {
      await handleDeleteEnvironment();
      notifications.show({
        title: 'Success',
        message: 'Environment configuration deleted successfully',
        color: 'green'
      });
      closeDeleteModal();
    } catch (error) {
      notifications.show({
        title: 'Error',
        message: error.message || 'Failed to delete environment configuration',
        color: 'red'
      });
    }
  };

  return (
    <Card shadow="sm" p="lg" radius="md" withBorder>
      <Card.Section withBorder inheritPadding py="xs">
        <Group position="apart">
          <Text fw={500} size="lg">Environment Configuration</Text>
          {environmentId && (
            <Badge 
              color={environmentData.is_passed_test ? 'green' : 'red'}
              leftSection={environmentData.is_passed_test ? <CheckCircle size={14} /> : <XCircle size={14} />}
            >
              {environmentData.is_passed_test ? 'Tests Passed' : 'Tests Failed'}
            </Badge>
          )}
        </Group>
      </Card.Section>

      <Stack spacing="md" mt="md">
        <Select
          label="Environment"
          placeholder="Select an environment"
          data={environmentData.environments}
          value={environmentData.environment_id}
          onChange={(value) => updateEnvironment({ environment_id: value })}
          required
          disabled={!!environmentId}
          searchable
        />

        {environmentData.environment_id && (
          <Select
            label="Commit Version"
            placeholder="Select a commit"
            data={environmentData.commits}
            value={environmentData.publish_commit}
            onChange={(value) => updateEnvironment({ publish_commit: value })}
            required
            disabled={!!environmentId || !environmentData.environment_id}
            searchable
          />
        )}

        {environmentData.passed_test_info_message && (
          <Text size="sm" color={environmentData.is_passed_test ? 'green' : 'red'}>
            {environmentData.passed_test_info_message}
          </Text>
        )}

        {environmentData.created_at_ts && (
          <Text size="sm" c="dimmed">
            Created: {new Date(environmentData.created_at_ts).toLocaleString()}
          </Text>
        )}

        <Group position="right" mt="xl">
          <Button 
            variant="filled" 
            onClick={handleSave}
            loading={loading}
            disabled={!environmentData.environment_id || !environmentData.publish_commit}
          >
            Save Environment
          </Button>
          
          {environmentId && (
            <Button 
              variant="light" 
              color="red" 
              onClick={openDeleteModal}
              loading={loading}
            >
              Delete Environment
            </Button>
          )}
        </Group>
      </Stack>

      <Modal
        opened={deleteModalOpened}
        onClose={closeDeleteModal}
        title="Delete Environment Configuration"
        centered
      >
        <Stack>
          <Text>Are you sure you want to delete this environment configuration? This action cannot be undone.</Text>
          <Group position="right">
            <Button variant="subtle" onClick={closeDeleteModal}>Cancel</Button>
            <Button 
              variant="filled" 
              color="red" 
              onClick={handleDelete}
              loading={loading}
            >
              Delete
            </Button>
          </Group>
        </Stack>
      </Modal>
    </Card>
  );
};

export default EnvironmentSection;