import { useState, useEffect } from "react";

const useLeaderboardData = (competitionId) => {
  const [leaderboardData, setLeaderboardData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    setLoading(true);
    const fetchLeaderboardData = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_PREFIX_BACKEND}/api/leaderboard/competition/${competitionId}`,
          { credentials: "include" }
        );
        if (response.ok) {
          const data = await response.json();
          setLeaderboardData(data);
          setError(null);
        } else {
          throw new Error(
            `Failed to fetch leaderboard data: ${response.status}`
          );
        }
      } catch (err) {
        console.error("Error fetching leaderboard data:", err);
        setError(err.message || "Failed to load data");
      } finally {
        setLoading(false);
      }
    };

    fetchLeaderboardData();
    const intervalId = setInterval(fetchLeaderboardData, 15000); // Refresh every 5 seconds

    return () => clearInterval(intervalId); // Clear interval on component unmount
  }, [competitionId]);

  // Correctly return an object with all stateful values
  return { leaderboardData, loading, error };
};

export default useLeaderboardData;
