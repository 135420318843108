import { useState, useEffect, useCallback } from "react";
import {
  fetchAttachmentCompetition,
  saveConfiguration,
  deleteConfiguration,
  fetchEngines,
} from "services/apiService";

const initialConfigurationState = {
  // Existing basic fields
  competition_id: null,
  number_of_agents: 2,
  agent_max_time_per_step_second: 0.5,
  engine_id: '',
  engines: [],
  
  // Resource limits - Environment
  env_cpu_limit: '500m',
  env_cpu_request: '200m',
  env_memory_limit: '1024Mi',
  env_memory_request: '256Mi',
  env_ephemeral_storage_limit: '15Gi',
  
  // Resource limits - Agent
  agent_cpu_limit: '3000m',
  agent_cpu_request: '1400m',
  agent_memory_limit: '3072Mi',
  agent_memory_request: '1024Mi',
  agent_ephemeral_storage_limit: '15Gi',
  agent_ephemeral_storage_request: '15Gi',
  
  // Timing and rendering
  notebook_blueprint_colab: '',
  render_delay_second: 0,
  render_end_second: 0,
  
  // Simulation configuration
  simulation_timeout_sec: 360,
  simulation_max_steps: 3000,
  env_max_time_per_step_second: 0.5,
  simulation_cpu_limit: '500m',
  simulation_memory_limit: '512Mi',
  simulation_cpu_request: '500m',
  simulation_memory_request: '512Mi',
  simulation_service_account_name: 'your-service-account',
  
  // Docker image configuration
  docker_image_worker_envagent_id: 1,
  docker_image_env_runtime_id: 1,
  env_agent_image_policy: 'IfNotPresent',
  
  // Docker image metadata from relationships (read-only)
  worker_image_grpc_version: 'v1',
  env_runtime_grpc_version: 'v1',
  env_runtime_language: 'python',
  env_runtime_language_version: '3.10'
};

export const useConfigurationData = (competitionId, configurationId) => {
  const [configurationData, setConfigurationData] = useState({
    ...initialConfigurationState,
    competition_id: competitionId
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  // Fetch engines for new configurations
  useEffect(() => {
    const fetchEngineData = async () => {
      if (configurationId) return; // Skip if editing existing configuration

      setLoading(true);
      try {
        const engines = await fetchEngines();
        const formattedEngines = engines.map(engine => ({
          value: String(engine.id), // Convert ID to string
          label: engine.name
        }));
        
        setConfigurationData(prevState => ({
          ...prevState,
          engines: formattedEngines,
          engine_id: formattedEngines[0]?.value || ''  // Use empty string as default
        }));
      } catch (err) {
        setError(err);
      } finally {
        setLoading(false);
      }
    };

    fetchEngineData();
  }, [configurationId]);

  // Fetch existing configuration data
  useEffect(() => {
    const fetchConfigData = async () => {
      if (!configurationId) return;

      setLoading(true);
      try {
        const data = await fetchAttachmentCompetition(competitionId, "configuration");
        // Convert engine_id to string if it exists
        const engineId = data.engine_id ? String(data.engine_id) : '';
        
        setConfigurationData(prevData => ({
          ...prevData,
          ...data,
          engine_id: engineId // Ensure engine_id is a string
        }));
      } catch (err) {
        setError(err);
      } finally {
        setLoading(false);
      }
    };

    fetchConfigData();
  }, [competitionId, configurationId]);

  const updateConfiguration = useCallback((updatedData) => {
    setConfigurationData(prevData => ({
      ...prevData,
      ...updatedData
    }));
  }, []);

  const handleSaveConfiguration = useCallback(async () => {
    setLoading(true);
    try {
      // Convert engine_id back to number for API
      const dataToSave = {
        ...configurationData,
        engine_id: configurationData.engine_id ? parseInt(configurationData.engine_id, 10) : null
      };
      
      const savedData = await saveConfiguration(configurationId, dataToSave);
      
      // Convert engine_id to string for state
      setConfigurationData(prevData => ({
        ...prevData,
        ...savedData,
        engine_id: savedData.engine_id ? String(savedData.engine_id) : ''
      }));
      return savedData;
    } catch (err) {
      setError(err);
      throw err;
    } finally {
      setLoading(false);
    }
  }, [configurationId, configurationData]);

  const handleDeleteConfiguration = useCallback(async () => {
    setLoading(true);
    try {
      await deleteConfiguration(configurationId);
      setConfigurationData({
        ...initialConfigurationState,
        competition_id: competitionId
      });
    } catch (err) {
      setError(err);
      throw err;
    } finally {
      setLoading(false);
    }
  }, [competitionId, configurationId]);

  return {
    configurationData,
    loading,
    error,
    updateConfiguration,
    handleSaveConfiguration,
    handleDeleteConfiguration
  };
};

export default useConfigurationData;