// index.js
import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App'; // Adjust the path if necessary

// Assuming your root div in index.html has an id of 'root'
const rootElement = document.getElementById('root');
const root = createRoot(rootElement);

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
