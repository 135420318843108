
import React, { useState } from 'react';
import { Stack, Title, Text, Card, List, Code, Button, Grid, Group } from '@mantine/core';
import { IconRocket, IconBrandGithub, IconBrandCodesandbox } from '@tabler/icons-react';
import AgentRunImageSelection from 'components/DirectAgentAttach/RuntimeSelector';

const GettingStarted = ({runtimeOptions, selectedImage, setSelectedImage, competitionInfo, environmentInfo, handleJoinCompetition }) => {

  if (!competitionInfo || !environmentInfo) {
    return <Text>Loading competition information...</Text>;
  }
  return (
    <Stack spacing="xl">
      <Title order={1}>Getting Started</Title>
      
      <Text>
        Welcome to the competition! This guide will quickly explain how the competition works and how to participate.
      </Text>

      <Grid gutter="md">
        <Grid.Col span={6}>
          <RLFramework competitionInfo={competitionInfo} environmentInfo={environmentInfo} />
        </Grid.Col>
        <Grid.Col span={6}>
          <EnvSpecifications environmentInfo={environmentInfo} />
        </Grid.Col>
      </Grid>

      <CompetitionConstraints competitionInfo={competitionInfo} environmentInfo={environmentInfo} />
      
      <HowToParticipate 
        runtimeOptions={runtimeOptions}
        environmentInfo={environmentInfo} 
        handleJoinCompetition={handleJoinCompetition}
        selectedImage={selectedImage}
        setSelectedImage={setSelectedImage}
      />
      
      <EvaluationRules competitionInfo={competitionInfo} />
      
      <ExampleInteraction competitionInfo={competitionInfo} environmentInfo={environmentInfo} />

      {competitionInfo.notebook_blueprint_colab && (
        <TrainingCode notebookUrl={competitionInfo.notebook_blueprint_colab} />
      )}
    </Stack>
  );
};

const RLFramework = ({ competitionInfo, environmentInfo }) => {
  const isSingleAgent = competitionInfo.number_of_agents === 1;

  return (
    <Card shadow="sm" padding="lg" radius="md" withBorder>
      <Title order={2}>RL Framework</Title>
      <Text mt="md">
        This competition uses a {isSingleAgent ? 'single-agent' : 'multi-agent'} reinforcement learning framework.
      </Text>
      <svg width="100%" height={isSingleAgent ? "200" : "300"} viewBox={isSingleAgent ? "0 0 400 200" : "0 0 400 300"}>
        {isSingleAgent ? (
          <>
            <rect x="50" y="80" width="100" height="40" fill="lightgray" stroke="black" />
            <text x="100" y="105" textAnchor="middle">Agent</text>
            
            <ellipse cx="250" cy="100" rx="80" ry="40" fill="lightgray" stroke="black" />
            <text x="250" y="105" textAnchor="middle">Environment</text>
            
            <path d="M100 80 L100 25 L100 25 L250 25 L250 50" fill="none" stroke="black" markerEnd="url(#arrowhead)" />
            <text x="170" y="20" textAnchor="middle">Action</text>
            
            <path d="M250 140 L250 170 L100 170 L100 130" fill="none" stroke="black" markerEnd="url(#arrowhead)" />
            <text x="180" y="185" textAnchor="middle" fontSize="10">Observation, Reward, Terminated, Truncated, Info</text>
            
            <defs>
              <marker id="arrowhead" markerWidth="10" markerHeight="7" refX="0" refY="3.5" orient="auto">
                <polygon points="0 0, 10 3.5, 0 7" />
              </marker>
            </defs>
          </>
        ) : (
          <>
<rect x="120" y="50" width="100" height="40" fill="lightgray" stroke="black" />
<text x="170" y="75" text-anchor="middle" fill="black">Player 1</text>

<rect x="120" y="250" width="100" height="40" fill="lightgray" stroke="black" />
<text x="170" y="275" text-anchor="middle" fill="black">Player 2</text>

            
<ellipse cx="300" cy="160" rx="80" ry="30" fill="lightgray" stroke="black" />
<text x="300" y="165" text-anchor="middle" fill="black">Environment Step 1</text>

<ellipse cx="50" cy="160" rx="80" ry="30" fill="lightgray" stroke="black" />
<text x="50" y="165" text-anchor="middle" fill="black">Environment Step 2</text>

            
<defs>
    <marker id="arrowhead" markerWidth="10" markerHeight="7" 
            refX="10" refY="3.5" orient="auto">
      <polygon points="0 0, 10 3.5, 0 7" />
    </marker>
  </defs>


  <path d="M220 70 L300 130" fill="none" stroke="black" marker-end="url(#arrowhead)" />
  

  <path d="M300 190 L220 270" fill="none" stroke="black" marker-end="url(#arrowhead)" />
  

  <path d="M120 270 L50 190" fill="none" stroke="black" marker-end="url(#arrowhead)" />
  

  <path d="M50 130 L120 70" fill="none" stroke="black" marker-end="url(#arrowhead)" />

          </>
        )}
      </svg>
      <Title order={3} mt="md">Key Components:</Title>
      <List>
        <List.Item>
          <Text weight={700}>Agent/Player:</Text> The entity that interacts with the environment, making decisions and taking actions.
        </List.Item>
        <List.Item>
          <Text weight={700}>Environment:</Text> The world in which the agent operates, providing observations and rewards based on the agent's actions.
        </List.Item>
        <List.Item>
          <Text weight={700}>Action:</Text> The decision made by the agent, which is then executed in the environment.
        </List.Item>
        <List.Item>
          <Text weight={700}>Observation:</Text> The information provided by the environment to the agent about its current state.
        </List.Item>
        <List.Item>
          <Text weight={700}>Reward:</Text> A scalar value indicating the immediate benefit of the action taken.
        </List.Item>
        <List.Item>
          <Text weight={700}>Terminated:</Text> A boolean indicating whether the episode has ended due to the agent reaching a goal or failing.
        </List.Item>
        <List.Item>
          <Text weight={700}>Truncated:</Text> A boolean indicating whether the episode was cut short (e.g., due to a time limit).
        </List.Item>
        <List.Item>
          <Text weight={700}>Info:</Text> Additional information provided by the environment, which may be useful for debugging or learning but not part of the agent's observation.
        </List.Item>
      </List>
    </Card>
  );
};


const EnvSpecifications = ({ environmentInfo }) => (
  <Card shadow="sm" padding="lg" radius="md" withBorder>
    <Title order={2}>Environment Specifications</Title>
    <List>
      <List.Item>Action shape: {JSON.stringify(environmentInfo.state_shape)}</List.Item>
      <List.Item>Observation shape: {JSON.stringify(environmentInfo.observation_shape)}</List.Item>
      <List.Item>Action mask: {environmentInfo.action_mask ? environmentInfo.action_mask : 'No'}</List.Item>
      <List.Item>Environment Code source and documentation: <a href={environmentInfo.url_code_source} target="_blank" rel="noopener noreferrer">{environmentInfo.url_code_source}</a></List.Item>
    </List>
  </Card>
);

const CompetitionConstraints = ({ competitionInfo, environmentInfo }) => (
  <Card shadow="sm" padding="lg" radius="md" withBorder>
    <Title order={2}>Competition Constraints</Title>
    <List>
      <List.Item>CPU limit: {competitionInfo.agent_cpu_limit}</List.Item>
      <List.Item>Memory limit: {competitionInfo.agent_memory_limit}</List.Item>
      <List.Item>Time per step: {competitionInfo.agent_max_time_per_step_second} seconds</List.Item>
    </List>
  </Card>
);

const HowToParticipate = ({ runtimeOptions, environmentInfo, handleJoinCompetition, selectedImage, setSelectedImage }) => (
  <Card shadow="sm" padding="lg" radius="md" withBorder>
    <Title order={2}>How to Participate</Title>
    <Text mt="md">To participate, you need to submit an agent that follows this structure:</Text>
    <Code block mt="md">
      {`class Agent:
    def __init__(self, env):
        self.env = env
        # from stable_baselines3 import DQN
        # self.model =  DQN.load("model.zip")

    def choose_action(self, observation, reward=0.0, terminated=False, truncated=False, info={}, action_mask=None):
        # action, _ = self.model.predict(observation, deterministic=True)
        return action`}
    </Code>
    <Text mt="md">Key points:</Text>
    <List>
      <List.Item>Create a file named `agent.py` with the `Agent` class</List.Item>
      <List.Item>Include the model.zip file (e.g., Stable Baselines3 PPO) </List.Item>
      <List.Item>The `choose_action` method should handle observations of shape: {JSON.stringify(environmentInfo.observation_shape)}</List.Item>
      <List.Item>Choose a runtime environment for your agent (see Agent Runtime Environment section below)</List.Item>
    </List>
    <AgentRunImageSelection
        runtimeOptions={runtimeOptions}
        selectedImage={selectedImage}
        setSelectedImage={setSelectedImage}
      />
    <Button 
      onClick={handleJoinCompetition} 
      size="lg" 
      color="blue" 
      mt="md"
      disabled={!selectedImage}
    >
      Join Your Agent To The Competition
    </Button>
  </Card>
);



const ExampleInteraction = ({competitionInfo, environmentInfo }) => {
  const isSinglePlayer = competitionInfo.number_of_agents === 1;

  const agentCode = isSinglePlayer
    ? `class Agent:
    def __init__(self, env):
        self.env = env

    def choose_action(self, observation, reward=0.0, terminated=False, truncated=False, info={}, action_mask=None):
        action = self.env.action_space.sample()
        return action`
    : `class Agent:
    def __init__(self, env):
        self.env = env
        self.action_space = self.env.action_space(self.env.agents[0])

    def choose_action(self, observation, reward=0.0, terminated=False, truncated=False, info={}, action_mask):
        action = self.action_space.sample(action_mask)
        return action`;

  const interactionCode = isSinglePlayer
    ? `${environmentInfo.model_code_import}
observation, info = env.reset()
cum_reward = 0

# Instantiate your agent
my_agent = Agent(env)

for _ in range(10):
    action = my_agent.choose_action(observation)
    observation, reward, terminated, truncated, info = env.step(action)
    cum_reward += reward
    if terminated or truncated:
        observation, info = env.reset()

env.close()`
    : `${environmentInfo.model_code_import}
cum_reward = {agent: 0 for agent in env.agents}

# Instantiate your agent (assuming first agent is controlled by your agent)
my_agent = Agent(env)

# Interact with the environment
for agent in env.agent_iter():
    observation, reward, termination, truncation, info = env.last()
    cum_reward[agent] += reward
    if termination or truncation:
        action = None
    else:
        mask = observation["action_mask"]
        if agent == env.agents[0]:  # Use your agent's policy for the first agent
            action = my_agent.choose_action(observation, mask)
        else:  # Random action for other agents
            action = env.action_space(agent).sample(mask)
    env.step(action)

env.close()
print(cum_reward)`;

  return (
    <Card shadow="sm" padding="lg" radius="md" withBorder>
      <Title order={2}>Example Agent-Environment Interaction</Title>
      <Text mt="md">
        Here's a simple example of how an agent interacts with the environment in a {isSinglePlayer ? "single-player" : "multi-player"} scenario:
      </Text>
      <Title order={3} mt="lg">Agent Definition</Title>
      <Code block mt="md">
        {agentCode}
      </Code>
      <Title order={3} mt="lg">Environment Interaction</Title>
      <Code block mt="md">
        {interactionCode}
      </Code>
      <Text mt="md">
        This example demonstrates a basic interaction between a random agent and the environment. 
        In a real scenario, you would replace the random action selection with your own intelligent agent's policy.
      </Text>
    </Card>
  );
};

const EvaluationRules = ({ competitionInfo }) => (
  <Card shadow="sm" padding="lg" radius="md" withBorder>
    <Title order={2}>Evaluation Rules</Title>
    <Text mt="md">
      Understanding how your agent will be evaluated is crucial for optimizing its performance:
    </Text>
    <List>
      <List.Item>
        <Text weight={700}>Daily Runs:</Text> You can choose how many times you want to run your agent daily, up to a maximum of {competitionInfo.maxDailyRuns} runs.
      </List.Item>
      <List.Item>
        <Text weight={700}>Competitive Matching:</Text> Your agent will compete against others based on their current performance level, ensuring fair and challenging matchups.
      </List.Item>
      <List.Item>
        <Text weight={700}>Leaderboard Ranking:</Text> The leaderboard is based on the mean reward achieved across all runs.
      </List.Item>
      <List.Item>
        <Text weight={700}>Performance Metrics:</Text> Besides the mean reward, consider other metrics like consistency and efficiency in your agent's decision-making process.
      </List.Item>
    </List>
    <Text mt="md">
      Remember, the goal is not just to achieve high scores, but to develop robust and efficient agents that perform well across multiple runs and scenarios.
    </Text>
  </Card>
);
const TrainingCode = ({ notebookUrl }) => {
  const isColabUrl = notebookUrl.startsWith('https://colab.research.google.com');

  const githubUrl = isColabUrl
    ? notebookUrl
        .replace('https://colab.research.google.com/github/', 'https://github.com/')
    : notebookUrl;

  const colabUrl = isColabUrl
    ? notebookUrl
    : `https://colab.research.google.com/github/${notebookUrl
        .replace('https://github.com/', '')}`

  return (
    <Card shadow="sm" padding="lg" radius="md" withBorder>
      <Title order={2}>Training Code</Title>
      <Text mt="md">
        To help you get started with training your agent, we've prepared a notebook with sample code and explanations.
        You can view and run this notebook on GitHub or Google Colab:
      </Text>
      <Group mt="lg">
        <Button 
          component="a"
          href={githubUrl}
          target="_blank"
          rel="noopener noreferrer"
          leftIcon={<IconBrandGithub size={20} />}
        >
          View on GitHub
        </Button>
        <Button 
          component="a"
          href={colabUrl}
          target="_blank"
          rel="noopener noreferrer"
          leftIcon={<IconBrandCodesandbox size={20} />}
        >
          Open in Google Colab
        </Button>
      </Group>
      <Text mt="md">
        This notebook contains example code for:
      </Text>
      <List>
        <List.Item>Setting up the environment</List.Item>
        <List.Item>Implementing a basic agent</List.Item>
        <List.Item>Training the agent using reinforcement learning algorithms</List.Item>
        <List.Item>Evaluating the agent's performance</List.Item>
      </List>
      <Text mt="md">
        Feel free to modify and experiment with this code to create your own unique agent!
      </Text>
    </Card>
  );
};

export default GettingStarted;