import React from 'react';
import { Modal, Button, Group, Text } from '@mantine/core';
import { useNavigate } from 'react-router-dom';

const MaxAgentsModal = ({ opened, onClose }) => {
  const navigate = useNavigate();

  const handleGoToSubscriptions = () => {
    onClose();
    navigate('/my-agents');
  };

  return (
    <Modal 
      opened={opened} 
      onClose={onClose} 
      title="Daily agent run limit" 
      centered
    >
      <Text size="sm" mb="xl">
        You've reached the maximum number of daily allocated agent runs. 
        Reduce the allocation for your other agents or contact support for more runs.
      </Text>
      <Group position="right" mt="md">
        <Button variant="default" onClick={onClose}>
          Close
        </Button>
        <Button onClick={handleGoToSubscriptions}>
          Go to My Agents
        </Button>
      </Group>
    </Modal>
  );
};

export default MaxAgentsModal;