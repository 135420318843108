import React from 'react';
import { Card, Text, Tabs } from '@mantine/core';
import { FileText, Image } from 'lucide-react';
import MarkdownEditor from "components/Competition/MarkdownEditor";
import ImageMiniature from "components/Competition/ImageMiniature";

const OverviewSection = ({ competitionId }) => {
  return (
    <Card shadow="sm" p="lg" radius="md" withBorder>
      <Card.Section withBorder inheritPadding py="xs">
        <Text fw={500} size="lg">Competition Overview</Text>
      </Card.Section>

      <Tabs defaultValue="description" mt="md">
        <Tabs.List>
          <Tabs.Tab 
            value="description" 
            leftSection={<FileText size={16} />}
          >
            Description
          </Tabs.Tab>
          <Tabs.Tab 
            value="thumbnail" 
            leftSection={<Image size={16} />}
          >
            Thumbnail
          </Tabs.Tab>
        </Tabs.List>

        <Tabs.Panel value="description" pt="md">
          <Card p="md" radius="sm" withBorder>
            <MarkdownEditor
              competitionId={competitionId}
              isViewOnly={false}
            />
          </Card>
        </Tabs.Panel>

        <Tabs.Panel value="thumbnail" pt="md">
          <Card p="md" radius="sm" withBorder>
            <ImageMiniature
              competitionId={competitionId}
              isViewOnly={false}
            />
          </Card>
        </Tabs.Panel>
      </Tabs>
    </Card>
  );
};

export default OverviewSection;