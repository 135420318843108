import React, { useEffect } from 'react';
import { Group, Image, Button, FileButton, Text, Paper, Loader } from '@mantine/core';
import { IconUpload, IconTrash } from '@tabler/icons-react';
import useImageMiniature from 'hooks/competition/useImageMiniature';

const ImageMiniature = ({ competitionId, isViewOnly }) => {
  const { image, loading, error, fetchImage, uploadImage, deleteImage } = useImageMiniature(competitionId);

  useEffect(() => {
    fetchImage();
  }, [fetchImage]);

  const handleFileChange = async (file) => {
    if (file) {
      await uploadImage(file);
    }
  };

  if (loading) return <Loader />;

  return (
    <Paper shadow="sm" p="md" withBorder>
      {error ? (
        <Text color="red">{error}</Text>
      ) : (
        <Image
          src={image}
          alt="Competition Miniature"
          radius="md"
          fit="contain"
          height={200}
          fallbackSrc="/placeholder.svg" // You should provide a placeholder image
        />
      )}
      {!isViewOnly && (
        <Group mt="md">
          <FileButton onChange={handleFileChange} accept="image/png,image/jpeg">
            {(props) => (
              <Button {...props} leftIcon={<IconUpload size="1rem" />}>
                Upload image
              </Button>
            )}
          </FileButton>
          <Button 
            onClick={deleteImage} 
            color="red" 
            leftIcon={<IconTrash size="1rem" />}
            disabled={!image}
          >
            Delete
          </Button>
        </Group>
      )}
    </Paper>
  );
};

export default ImageMiniature;