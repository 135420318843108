// src/services/apiService.js

const API_PREFIX = process.env.REACT_APP_PREFIX_BACKEND;

const handleResponse = async (response) => {
  if (!response.ok) {
    const error = await response.text();
    throw new Error(error);
  }
  return response.json();
};

export const fetchEngines = async () => {
  const endpoint = `${API_PREFIX}/api/engines/`;
  const response = await fetch(endpoint, { credentials: "include" });
  return handleResponse(response);
};

export const fetchCompetition = async (competitionId) => {
  const endpoint = `${API_PREFIX}/api/competitions/${competitionId || ""}`;
  const response = await fetch(endpoint, { credentials: "include" });
  return handleResponse(response);
};

export const fetchAttachments = async (competitionId) => {
  const endpoints = {
    configuration: `${API_PREFIX}/api/attaches/configuration/competition/${
      competitionId || ""
    }`,
    environment: `${API_PREFIX}/api/attaches/environment/competition/${
      competitionId || ""
    }`,
    agent: `${API_PREFIX}/api/attaches/agent/competition/${
      competitionId || ""
    }`,
  };

  try {
    const allAttachments = await Promise.all(
      Object.values(endpoints).map((endpoint) =>
        fetch(endpoint, { credentials: "include" }).then(handleResponse)
      )
    );

    return {
      configurationId: allAttachments[0]?.id,
      environmentId: allAttachments[1]?.id,
      agentIds: allAttachments[2]?.map((agent) => agent.id),
    };
  } catch (error) {
    console.error("Error fetching attachments:", error);
    throw error;
  }
};

export const saveCompetition = async (competitionId, data) => {
  const method = competitionId ? "PUT" : "POST";
  const endpoint = `${process.env.REACT_APP_PREFIX_BACKEND}/api/competitions/${
    competitionId || ""
  }`;

  return fetch(endpoint, {
    method: method,
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data),
    credentials: "include",
  }).then(handleResponse);
};

export const deleteCompetition = async (competitionId) => {
  return fetch(
    `${process.env.REACT_APP_PREFIX_BACKEND}/api/competitions/${competitionId}`,
    {
      method: "DELETE",
      credentials: "include",
    }
  ).then(handleResponse);
};

export const fetchAttachmentCompetition = async (
  competitionId,
  attachmentType
) => {
  const endpoint = `${API_PREFIX}/api/attaches/${attachmentType}/competition/${
    competitionId || ""
  }`;
  const response = await fetch(endpoint, { credentials: "include" });
  return handleResponse(response);
};

export const fetchAttachment = async (objectIdId, attachmentType) => {
  const endpoint = `${API_PREFIX}/api/attaches/${attachmentType}/${objectIdId}`;
  const response = await fetch(endpoint, { credentials: "include" });
  return handleResponse(response);
};

export const fetchEnvironments = async () => {
  const attachmentType = "environment";
  const endpoint = `${API_PREFIX}/api/repositories/${attachmentType}/`;
  const response = await fetch(endpoint, { credentials: "include" });
  return handleResponse(response);
};

export const fetchAgents = async () => {
  const attachmentType = "agent";
  const endpoint = `${API_PREFIX}/api/repositories/${attachmentType}/`;
  const response = await fetch(endpoint, { credentials: "include" });
  return handleResponse(response);
};

export const fetchCommits = async (attachmentType, environmentId) => {
  const endpoint = `${API_PREFIX}/api/repositories/${attachmentType}/${environmentId}`;
  const response = await fetch(endpoint, { credentials: "include" });
  return handleResponse(response);
};

export const saveConfiguration = async (configurationId, data) => {
  const attachmentType = "configuration";
  const method = configurationId ? "PUT" : "POST";
  const endpoint = `${
    process.env.REACT_APP_PREFIX_BACKEND
  }/api/attaches/${attachmentType}/${configurationId || ""}`;

  return fetch(endpoint, {
    method: method,
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data),
    credentials: "include",
  }).then(handleResponse);
};

export const deleteConfiguration = async (configurationId) => {
  const attachmentType = "configuration";
  return fetch(
    `${process.env.REACT_APP_PREFIX_BACKEND}/api/attaches/${attachmentType}/${configurationId}`,
    {
      method: "DELETE",
      credentials: "include",
    }
  ).then(handleResponse);
};

export const saveEnvironment = async (environmentId, data) => {
  const attachmentType = "environment";
  const method = environmentId ? "PUT" : "POST";
  const endpoint = `${
    process.env.REACT_APP_PREFIX_BACKEND
  }/api/attaches/${attachmentType}/${environmentId || ""}`;

  return fetch(endpoint, {
    method: method,
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data),
    credentials: "include",
  }).then(handleResponse);
};

export const deleteEnvironment = async (environmentId) => {
  const attachmentType = "environment";
  return fetch(
    `${process.env.REACT_APP_PREFIX_BACKEND}/api/attaches/${attachmentType}/${environmentId}`,
    {
      method: "DELETE",
      credentials: "include",
    }
  ).then(handleResponse);
};

export const saveAgent = async (agentId, data) => {
  const method = agentId ? "PUT" : "POST";
  const endpoint = `${
    process.env.REACT_APP_PREFIX_BACKEND
  }/api/attaches/agent/${agentId || ""}`;

  return fetch(endpoint, {
    method: method,
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data),
    credentials: "include",
  }).then(handleResponse);
};

export const deleteAgent = async (agentId) => {
  return fetch(
    `${process.env.REACT_APP_PREFIX_BACKEND}/api/attaches/agent/${agentId}`,
    {
      method: "DELETE",
      credentials: "include",
    }
  ).then(handleResponse);
};
