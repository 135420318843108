import { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { notifications } from '@mantine/notifications';

export const useAgentFileManagement = (competitionId, attachmentId, onStatusChange) => {
  const [fileState, setFileState] = useState({
    isUploading: false,
    error: null,
    files: [],
    agentCode: null,
    isLoading: true
  });

  const fetchFiles = useCallback(async () => {
    try {
      setFileState(prev => ({ ...prev, isLoading: true }));
      const response = await axios.get(
        `/api/direct_attache_agents/competition/${competitionId}/${attachmentId}/file`
      );
      setFileState(prev => ({
        ...prev,
        files: response.data.files,
        agentCode: response.data.agentCode,
        isLoading: false,
        error: null
      }));
    } catch (error) {
      console.error('Failed to fetch files:', error);
      setFileState(prev => ({
        ...prev,
        error: 'Failed to fetch files',
        isLoading: false
      }));
      notifications.show({
        title: 'Error',
        message: 'Failed to fetch files',
        color: 'red'
      });
    }
  }, [competitionId, attachmentId]);

  useEffect(() => {
    fetchFiles();
  }, [fetchFiles]);

  const handlers = {
    handleUpload: async (file) => {
      setFileState(prev => ({ ...prev, isUploading: true, error: null }));
      try {
        const formData = new FormData();
        formData.append('file', file);
        const response = await axios.put(
          `/api/direct_attache_agents/competition/${competitionId}/${attachmentId}/file`,
          formData
        );
        await fetchFiles();
        onStatusChange();
        notifications.show({
          title: 'Success',
          message: 'File uploaded successfully',
          color: 'green'
        });
        return response.data;
      } catch (error) {
        const errorMessage = error.response?.data?.error || 'Upload failed';
        setFileState(prev => ({ ...prev, error: errorMessage }));
        notifications.show({
          title: 'Error',
          message: errorMessage,
          color: 'red'
        });
        throw error;
      } finally {
        setFileState(prev => ({ ...prev, isUploading: false }));
      }
    },

    handleFileDelete: async (filename) => {
      setFileState(prev => ({ ...prev, isUploading: true, error: null }));
      try {
        const formData = new FormData();
        formData.append('delete_file', filename);
        const response = await axios.put(
          `/api/direct_attache_agents/competition/${competitionId}/${attachmentId}/file`,
          formData
        );
        await fetchFiles();
        onStatusChange();
        notifications.show({
          title: 'Success',
          message: 'File deleted successfully',
          color: 'green'
        });
        return response.data;
      } catch (error) {
        notifications.show({
          title: 'Error',
          message: error.response?.data?.error || 'Failed to delete file',
          color: 'red'
        });
        throw error;
      }finally {
        setFileState(prev => ({ ...prev, isUploading: false }));
      }
    },

    handleEditFile: async (filename, content) => {
      try {
        const formData = new FormData();
        const file = new File([content], filename, { type: 'text/x-python' });
        formData.append('file', file);
        const response = await axios.put(
          `/api/direct_attache_agents/competition/${competitionId}/${attachmentId}/file`,
          formData
        );
        await fetchFiles();
        onStatusChange();
        notifications.show({
          title: 'Success',
          message: 'File saved successfully',
          color: 'green'
        });
        return response.data;
      } catch (error) {
        notifications.show({
          title: 'Error',
          message: error.response?.data?.error || 'Failed to save file',
          color: 'red'
        });
        throw error;
      }finally {
        setFileState(prev => ({ ...prev, isUploading: false }));
      }
    },

    handleDownload: async (filename) => {
      try {
        const response = await axios.get(
          `/api/direct_attache_agents/competition/${competitionId}/${attachmentId}/file/${filename}`,
          { responseType: 'blob' }
        );
        
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', filename);
        document.body.appendChild(link);
        link.click();
        link.remove();
        window.URL.revokeObjectURL(url); // Clean up the URL object
        
        notifications.show({
          title: 'Success',
          message: 'File downloaded successfully',
          color: 'green'
        });
      } catch (error) {
        console.error('Failed to download file:', error);
        notifications.show({
          title: 'Error',
          message: 'Failed to download file',
          color: 'red'
        });
      }
    }
  };

  const handleAgentCodeChange = (newCode) => {
    setFileState(prev => ({
      ...prev,
      agentCode: newCode
    }));
  };

  return {
    fileState,
    handlers,
    fetchFiles,
    handleAgentCodeChange
  };
};