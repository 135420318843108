import { Card, Grid, Box, Text, Title, Group, Tooltip, Stack } from '@mantine/core';
import { IconRun } from '@tabler/icons-react';

const DailyRunAllocation = ({ dailyAgentRunLimit, totalDailyRunAllocation }) => {
  const availableRuns = dailyAgentRunLimit - totalDailyRunAllocation;

  // Utility function to generate runner icons
  const renderRunners = (count) => {
    const icons = [];
    for (let i = 0; i < count; i++) {
      icons.push(<IconRun size={20} key={i} />);
    }
    return icons;
  };

  return (
<Stack spacing="xl">
      <Grid align="center" justify="center" gutter="lg">

        {/* Allocated Runs Box */}
        <Grid.Col span="content">
          <Box
            sx={(theme) => ({
              backgroundColor: theme.colors.blue[1],
              padding: theme.spacing.md,
              borderRadius: theme.radius.md,
              textAlign: 'center',
              boxShadow: theme.shadows.sm,
            })}
          >
            <Text size="lg" weight={600}>Allocated</Text>
            <Group position="center">
              {renderRunners(totalDailyRunAllocation)} {/* Render runners */}
            </Group>
            <Text mt="sm" size="xl" weight={700}>
              {totalDailyRunAllocation}
            </Text>
          </Box>
        </Grid.Col>

        {/* Equal Sign */}
        <Grid.Col span="content">
          <Text align="center" size="xl" weight={700}>+</Text>
        </Grid.Col>

        {/* Available Runs Box */}
        <Grid.Col span="content">
          <Box
            sx={(theme) => ({
              backgroundColor: theme.colors.green[1],
              padding: theme.spacing.md,
              borderRadius: theme.radius.md,
              textAlign: 'center',
              boxShadow: theme.shadows.sm,
            })}
          >
            <Text size="lg" weight={600}>Available</Text>
            <Group position="center">
              {renderRunners(availableRuns)} {/* Render runners */}
            </Group>
            <Text mt="sm" size="xl" weight={700}>
              {availableRuns}
            </Text>
          </Box>
        </Grid.Col>
        {/* Equal Sign */}
        <Grid.Col span="content">
          <Text align="center" size="xl" weight={700}>=</Text>
        </Grid.Col>
        {/* Total Runs Box */}
        <Grid.Col span="content">
          <Box
            sx={(theme) => ({
              backgroundColor: theme.colors.gray[1],
              padding: theme.spacing.md,
              borderRadius: theme.radius.md,
              textAlign: 'center',
              boxShadow: theme.shadows.sm,
            })}
          >
            <Text size="lg" weight={600}>Your daily agent runs</Text>
            <Group position="center">
              {renderRunners(dailyAgentRunLimit)} {/* Render runners */}
            </Group>
            <Text mt="sm" size="xl" weight={700}>
              {dailyAgentRunLimit}
            </Text>
          </Box>
        </Grid.Col>
      </Grid>
</Stack>
  );
};

export default DailyRunAllocation;
