import React, { useState, useEffect } from 'react';
import { Box, Text, Group, RingProgress, Center } from '@mantine/core';

const Timer = ({ startTime, endTime }) => {
  const [progress, setProgress] = useState(0);
  const [timeLeft, setTimeLeft] = useState('');

  useEffect(() => {
    const interval = setInterval(() => {
      const now = new Date();
      const total = endTime.getTime() - startTime.getTime();
      const elapsed = now.getTime() - startTime.getTime();
      const newProgress = Math.min((elapsed / total) * 100, 100);
      setProgress(newProgress);

      if (now >= endTime) {
        setTimeLeft('Competition has ended');
        clearInterval(interval);
      } else {
        const remaining = endTime.getTime() - now.getTime();
        const days = Math.floor(remaining / (1000 * 60 * 60 * 24));
        const hours = Math.floor((remaining % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        const minutes = Math.floor((remaining % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((remaining % (1000 * 60)) / 1000);
        setTimeLeft(`${days}d ${hours}h ${minutes}m ${seconds}s`);
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [startTime, endTime]);

  return (
    <Group position="apart" align="center">
      <Box>
        <Text size="xl" weight={700}>Competition Progress</Text>
        <Text size="sm" color="dimmed">{timeLeft}</Text>
      </Box>
      <RingProgress
        size={120}
        thickness={12}
        sections={[{ value: progress, color: progress === 100 ? 'red' : 'blue' }]}
        label={
          <Center>
            <Text size="xl" weight={700}>{Math.round(progress)}%</Text>
          </Center>
        }
      />
    </Group>
  );
};

export default Timer;