import { useState, useEffect, useCallback } from 'react';
import { 
  fetchAttachmentCompetition, 
  saveEnvironment, 
  deleteEnvironment, 
  fetchEnvironments, 
  fetchCommits 
} from 'services/apiService';

const initialEnvironmentState = {
  competition_id: null,
  environment_id: '',
  publish_commit: '',
  created_at_ts: null,
  is_passed_test: false,
  passed_test_info_message: '',
  environments: [],
  commits: []
};

export const useEnvironmentData = (competitionId, environmentId) => {
  const [environmentData, setEnvironmentData] = useState({
    ...initialEnvironmentState,
    competition_id: competitionId
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  // Fetch available environments
  useEffect(() => {
    const fetchEnvironmentList = async () => {
      setLoading(true);
      try {
        const envs = await fetchEnvironments();
        const formattedEnvs = envs.map(env => ({
          value: String(env.id), // Convert ID to string
          label: env.name
        }));

        setEnvironmentData(prevState => ({
          ...prevState,
          environments: formattedEnvs,
          environment_id: environmentId ? String(prevState.environment_id) : formattedEnvs[0]?.value || ''
        }));
      } catch (err) {
        setError(err);
      } finally {
        setLoading(false);
      }
    };

    fetchEnvironmentList();
  }, []);

  // Fetch commits when environment is selected
  useEffect(() => {
    const fetchCommitList = async () => {
      if (!environmentData.environment_id) {
        // Clear commits if no environment is selected
        setEnvironmentData(prevState => ({
          ...prevState,
          commits: [],
          publish_commit: ''
        }));
        return;
      }
  
      setLoading(true);
      try {
        const { commits } = await fetchCommits(
          'environment', 
          parseInt(environmentData.environment_id, 10)
        );
        const formattedCommits = commits.map(commit => {
          const [hash, message] = commit.split(' ');
          return {
            value: hash,
            label: message || hash
          };
        });
  
        setEnvironmentData(prevState => ({
          ...prevState,
          commits: formattedCommits,
          publish_commit: environmentId ? prevState.publish_commit : formattedCommits[0]?.value || ''
        }));
      } catch (err) {
        setError(err);
        // Clear commits on error
        setEnvironmentData(prevState => ({
          ...prevState,
          commits: [],
          publish_commit: ''
        }));
      } finally {
        setLoading(false);
      }
    };
  
    fetchCommitList();
  }, [environmentData.environment_id]); 

  // Fetch existing environment data if editing
  useEffect(() => {
    const fetchExistingEnvironment = async () => {
      if (!environmentId) return;

      setLoading(true);
      try {
        const data = await fetchAttachmentCompetition(competitionId, 'environment');
        setEnvironmentData(prevData => ({
          ...prevData,
          ...data,
          environment_id: String(data.environment_id), // Convert ID to string
          publish_commit: String(data.publish_commit) // Ensure commit hash is string
        }));
      } catch (err) {
        setError(err);
      } finally {
        setLoading(false);
      }
    };

    fetchExistingEnvironment();
  }, [environmentId, competitionId]);

  const updateEnvironment = useCallback((updatedData) => {
    setEnvironmentData(prevData => ({
      ...prevData,
      ...updatedData
    }));
  }, []);

  const handleSaveEnvironment = useCallback(async () => {
    setLoading(true);
    try {
      // Convert environment_id back to number for API
      const dataToSave = {
        ...environmentData,
        environment_id: parseInt(environmentData.environment_id, 10)
      };
  
      // If editing, pass the environmentId for the PUT request
      const savedData = await saveEnvironment(
        environmentId || '',  // Pass environmentId for PUT, empty string for POST
        dataToSave
      );
      
      // Convert IDs back to strings for state
      setEnvironmentData(prevData => ({
        ...prevData,
        ...savedData,
        environment_id: String(savedData.environment_id),
        publish_commit: String(savedData.publish_commit)
      }));
      return savedData;
    } catch (err) {
      setError(err);
      throw err;
    } finally {
      setLoading(false);
    }
  }, [environmentId, environmentData]);

  const handleDeleteEnvironment = useCallback(async () => {
    setLoading(true);
    try {
      await deleteEnvironment(competitionId);
      setEnvironmentData({
        ...initialEnvironmentState,
        competition_id: competitionId
      });
    } catch (err) {
      setError(err);
      throw err;
    } finally {
      setLoading(false);
    }
  }, [competitionId]);

  return {
    environmentData,
    loading,
    error,
    updateEnvironment,
    handleSaveEnvironment,
    handleDeleteEnvironment
  };
};

export default useEnvironmentData;