import React, { useEffect, useState } from 'react';
import { Card, Image, Text, Group, Badge, Skeleton, Container } from '@mantine/core';
import { IconUsers, IconClock } from '@tabler/icons-react';
import axios from 'axios';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';

dayjs.extend(relativeTime);

const CurrentCompetition = ({ competitionId }) => {
  const [competition, setCompetition] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchCompetition = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_PREFIX_BACKEND}/api/competitions/${competitionId}`);
        setCompetition(response.data);
      } catch (error) {
        console.error('Error fetching competition:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchCompetition();
  }, [competitionId]);

  const getTimeRemaining = (endTime) => {
    if (!endTime) return 'No end time set';
    const now = dayjs();
    const end = dayjs(endTime);
    if (end.isBefore(now)) return 'Ended';
    return end.from(now, true) + ' left';
  };

  if (loading) {
    return (
      <Card shadow="sm" padding={0} radius={0} mb="md" style={{ width: '100vw', marginLeft: 'calc(-50vw + 50%)' }}>
        <Skeleton height={240} mb="md" />
      </Card>
    );
  }

  if (!competition) {
    return null;
  }

  return (
    <Card shadow="sm" padding={0} radius={0} mb="md" style={{ width: '100vw', marginLeft: 'calc(-50vw + 50%)' }}>
      <div style={{ position: 'relative' }}>
        {competition.miniature ? (
          <Image
            src={`${process.env.REACT_APP_PREFIX_BACKEND}${competition.miniature}` || '/path/to/default/image.png'}
            height={240}
            alt={competition.name}
          />
        ) : (
          <div style={{ height: 240, background: '#f0f0f0', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <Text color="dimmed">No image available</Text>
          </div>
        )}
        <div style={{ position: 'absolute', bottom: 0, left: 0, right: 0, background: 'rgba(0,0,0,0.7)', padding: '20px' }}>
          <Container size="lg">
            <Group position="apart" mb="xs">
              <Text weight={700} size="xl" color="white">{competition.name}</Text>
              <Badge color={competition.is_started ? "green" : "red"} variant="filled" size="lg">
                {competition.is_started ? "Active" : "Inactive"}
              </Badge>
            </Group>
            <Group spacing="lg">
              <Group spacing={4}>
                <IconUsers size={18} color="white" />
                <Text size="md" color="white">{competition.number_of_agents || 'N/A'} Players</Text>
              </Group>
              <Group spacing={4}>
                <IconClock size={18} color="white" />
                <Text size="md" color="white">{getTimeRemaining(competition.programmed_end_at_ts)}</Text>
              </Group>
            </Group>
          </Container>
        </div>
      </div>
    </Card>
  );
};

export default CurrentCompetition;