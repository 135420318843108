import React from 'react';
import { Stack, Title, Container, Group, Button, Box } from '@mantine/core';
import LiveStream from 'components/Competition/LiveStream';
import Timer from 'components/Competition/Timer';
import CompetitionConstraints from 'components/Competition/CompetitionConstraints';
import MarkdownEditor from 'components/Competition/MarkdownEditor';
import OverviewAgentEnvironmentInteraction from 'components/Competition/OverviewAgentEnvironmentInteraction';

const Overview = ({ 
  competitionId, 
  startTime, 
  endTime, 
  renderDelay,
  agentMemoryLimit, 
  agentCpuLimit, 
  agentMaxTimePerStepSecond,
  competitionInfo, // Added new prop
  environmentInfo, // Added new prop
  onJoinCompetition 
}) => {
  // Early return if required info isn't available
  if (!competitionInfo || !environmentInfo) {
    return (
      <Container size="xl" px="xs">
        <Stack spacing="xl">
          <Title order={2}>Loading competition information...</Title>
        </Stack>
      </Container>
    );
  }

  return (
    <Container size="xl" px="xs">
      <Stack spacing="xl">
        <Group grow align="flex-start">
          <Timer startTime={startTime} endTime={endTime} />
          <CompetitionConstraints
            memoryLimit={agentMemoryLimit || 'N/A'}
            cpuLimit={agentCpuLimit || 'N/A'}
            maxTimePerStep={agentMaxTimePerStepSecond || 'N/A'}
          />
        </Group>
        
        {renderDelay !== undefined && (
          <Box>
            <LiveStream renderDelay={renderDelay * 1000} />
          </Box>
        )}

        <OverviewAgentEnvironmentInteraction 
          competitionInfo={competitionInfo} 
          environmentInfo={environmentInfo} 
        />

        <Box>
          <MarkdownEditor competitionId={competitionId} isViewOnly={true} />
        </Box>
      </Stack>
    </Container>
  );
};

export default Overview;