import React from 'react';
import { Alert, Text, Anchor } from '@mantine/core';
import { IconAlertTriangle } from '@tabler/icons-react';

const BetaBanner = () => {
  return (
    <Alert
      icon={<IconAlertTriangle size="1.1rem" />}
      title="Beta Version"
      color="yellow"
      radius="md"
    >
      <Text size="sm">
        This is a beta version. Please report any issues to{' '}
        <Anchor
          href="https://github.com/rlarena/notebook_blueprint/issues"
          target="_blank"
          rel="noopener noreferrer"
        >
          our GitHub issues page
        </Anchor>
        .
      </Text>
    </Alert>
  );
};

export default BetaBanner;