import React from 'react';
import { Stack } from '@mantine/core';
import Leaderboard from 'components/Competition/Leaderboard';
import SimulationBanner from 'components/Competition/SimulationBanner';

const LeaderboardTab = ({ competitionId }) => {
  return (
    <Stack spacing="xl">
      <SimulationBanner competitionId={competitionId} />
      <Leaderboard competitionId={competitionId} />
    </Stack>
  );
};

export default LeaderboardTab;