import React from 'react';
import { Container, Title, Text, Paper, Space } from '@mantine/core';

const CompanyInfo = () => {
  return (
    <Container size="md" py="xl">
      <Paper shadow="xs" p="md" withBorder>
        <Title order={1} align="center" mb="md">
          About RLarena.com
        </Title>
        <Text size="lg" align="justify">
          RLarena.com is dedicated to building a platform and online community
          for data scientists and machine learning practitioners with a focus
          on reinforcement learning. Our mission is to facilitate the sharing
          of knowledge, resources, and opportunities in the field of
          reinforcement learning, making it accessible and engaging for
          everyone from beginners to advanced practitioners.
        </Text>
        <Space h="md" />
        <Text size="lg" align="justify">
          We strive to create an environment where innovation thrives,
          collaboration is encouraged, and the boundaries of reinforcement
          learning are continually pushed. Join us in shaping the future of
          AI and machine learning!
        </Text>
      </Paper>
    </Container>
  );
};

export default CompanyInfo;