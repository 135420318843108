import React, { createContext, useContext, useState, useEffect } from "react";
import axios from "axios";
axios.defaults.baseURL = `${process.env.REACT_APP_PREFIX_BACKEND}`;

const AuthContext = createContext();

export function useAuth() {
  return useContext(AuthContext);
}

export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchCurrentUser = async () => {
        axios.get('/api/auth/current_user')
          .then((response) => {
            const { data } = response; 
            if (data?.isAuthenticated) {
              setCurrentUser(data);
              setIsAuthenticated(true)
            }
          })
          .catch((error) => {
            console.error("Failed to fetch current user", error);
          })
          .finally(()=>{
            setLoading(false);
          })
    };
    fetchCurrentUser();
  }, []);

  const value = {
    currentUser,
    isAuthenticated: !!currentUser,
    isAdmin: currentUser?.is_admin,
    setCurrentUser,
    setIsAuthenticated,
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
};
