import React, { useState, useEffect } from 'react';
import { 
  Container, Text, Badge, Progress, Alert, Loader, Select,
  Card, Group, Stack, Title, Divider
} from '@mantine/core';
import { 
  Trophy, TrendingUp, TrendingDown, Timer,
  AlertTriangle, CheckCircle, Activity, Clock,Target,
  Cpu, HardDrive
} from 'lucide-react';

const AgentResultsDashboard = ({ competitionId, attacheAgentId }) => {
  const [results, setResults] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [filter, setFilter] = useState('all');

  useEffect(() => {
    const fetchResults = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_PREFIX_BACKEND}/api/agent_attached_result/${competitionId}/${attacheAgentId}`
        );
        if (!response.ok) throw new Error('Failed to fetch results');
        const data = await response.json();
        setResults(data);
        setIsLoading(false);
      } catch (err) {
        setError(err.message);
        setIsLoading(false);
      }
    };

    fetchResults();
  }, [competitionId, attacheAgentId]);

  if (isLoading) return <Loader />;
  if (error) return (
    <Alert icon={<AlertTriangle size={16} />} title="Error" color="red">
      {error}
    </Alert>
  );

  const filteredResults = results.filter(run => {
    if (filter === 'all') return true;
    if (filter === 'completed') return run.status === 'completed';
    if (filter === 'failed') return run.status === 'error';
    if (filter === 'running') return run.status === 'running';
    return true;
  });

  return (
    <Container size="xl" py="md">
      <Group position="apart" mb="md">
        <Stack spacing={4}>
          <Title order={2}>Simulation Results</Title>
          <Text size="sm" color="dimmed">
            Showing {filteredResults.length} simulations
          </Text>
        </Stack>
        <Select
          value={filter}
          onChange={setFilter}
          data={[
            { value: 'all', label: 'All Results' },
            { value: 'completed', label: 'Completed' },
            { value: 'failed', label: 'Failed' },
            { value: 'running', label: 'Running' }
          ]}
        />
      </Group>
      
      <Stack spacing="md">
        {filteredResults.map(run => (
          <SimulationCard
            key={run.simulation_id}
            run={run}
          />
        ))}
      </Stack>
    </Container>
  );
};

const SimulationCard = ({ run }) => {
  const getStatusInfo = (status) => ({
    completed: { icon: <CheckCircle size={16} />, color: 'green' },
    error: { icon: <AlertTriangle size={16} />, color: 'red' },
    running: { icon: <Activity size={16} />, color: 'blue' }
  }[status] || { icon: <Clock size={16} />, color: 'gray' });

  const mainAgent = run.agent_infos[0];
  const { icon: statusIcon, color: statusColor } = getStatusInfo(run.status);
  const isEloMode = run.evaluation_type === 'elo_score';

  const formatBytes = (bytes) => {
    if (!bytes) return 'N/A';
    const sizes = ['B', 'KB', 'MB', 'GB'];
    const i = Math.floor(Math.log(bytes) / Math.log(1024));
    return `${(bytes / Math.pow(1024, i)).toFixed(2)} ${sizes[i]}`;
  };

  return (
    <Card shadow="sm" withBorder>
      <Stack spacing="md">
        {/* Header Section */}
        <Group position="apart">
          <Group spacing="md">
            <Badge leftSection={statusIcon} color={statusColor}>
              {run.status.toUpperCase()}
            </Badge>
            {isEloMode && mainAgent?.game_outcome && (
              <GameOutcomeBadge outcome={mainAgent.game_outcome} />
            )}
          </Group>
          <Group spacing="md">
            {isEloMode && mainAgent?.score_elo_before && (
              <Badge
                variant="light"
                color="gray"
                leftSection={<Target size={14} />}
              >
                ELO: {mainAgent.score_elo_before}
              </Badge>
            )}
            {isEloMode && mainAgent?.score_elo_delta && (
              <EloChange delta={mainAgent.score_elo_delta} />
            )}
            <Text size="sm" color="dimmed">ID: {run.simulation_id}</Text>
          </Group>
        </Group>

        {/* Timing Information */}
        <Group position="apart" spacing="xl">
          <Text size="sm" color="dimmed">
            Started: {new Date(run.created_at_ts).toLocaleString()}
          </Text>
          {run.end_time && (
            <Text size="sm" color="dimmed">
              Ended: {new Date(run.end_time).toLocaleString()}
            </Text>
          )}
        </Group>

        {/* Main Metrics */}
        {mainAgent && (
          <>
            <Group grow spacing="xl">
              <Stack spacing={4}>
                <Text size="sm" weight={500}>Reward</Text>
                <Text size="lg">
                  {mainAgent.agent_reward?.toFixed(2) || 'N/A'}
                </Text>
              </Stack>
              <Stack spacing={4}>
                <Text size="sm" weight={500}>Steps</Text>
                <Text size="lg">
                  {mainAgent.metrics.steps || 'N/A'}
                </Text>
              </Stack>
              {run.number_agents && (
                <Stack spacing={4}>
                  <Text size="sm" weight={500}>Agents</Text>
                  <Text size="lg">{run.number_agents}</Text>
                </Stack>
              )}
            </Group>

            <Divider />

            {/* Performance Metrics */}
            <Stack spacing="sm">
              <MetricRow
                icon={<Timer size={16} />}
                title="Action Time"
                mean={`${mainAgent.metrics.action_time.mean?.toFixed(3)}s`}
                max={`${mainAgent.metrics.action_time.max?.toFixed(3)}s`}
              />

              <MetricRow
                icon={<Cpu size={16} />}
                title="CPU Usage"
                mean={`${mainAgent.metrics.cpu.percent_mean?.toFixed(1)}%`}
                max={`${mainAgent.metrics.cpu.percent_max?.toFixed(1)}%`}
                progress={mainAgent.metrics.cpu.percent_mean}
                alert={mainAgent.metrics.cpu.percent_mean > 80}
              />

              <MetricRow
                icon={<HardDrive size={16} />}
                title="Memory Usage"
                mean={formatBytes(mainAgent.metrics.ram.mean_bytes)}
                max={formatBytes(mainAgent.metrics.ram.max_bytes)}
                progress={mainAgent.metrics.ram.mean_percent}
                alert={mainAgent.metrics.ram.mean_percent > 80}
              />
            </Stack>
          </>
        )}

        {/* Opponents Section */}
        {run.opponents.length > 0 && (
          <>
            <Divider label="Opponents" labelPosition="left" />
            <Stack spacing="xs">
              {run.opponents.map((opponent, idx) => (
                <Group key={idx} position="apart">
                  <Badge variant="outline" size="lg">
                    {opponent.agent_name}
                    <Text span size="xs" ml={4} color="dimmed">
                      ({opponent.user_name})
                    </Text>
                  </Badge>
                  {isEloMode && (
                    <Group spacing="xs">
                      {opponent.score_elo_before && (
                        <Badge variant="light" color="gray" leftSection={<Target size={14} />}>
                          ELO: {opponent.score_elo_before}
                        </Badge>
                      )}
                      {opponent.score_elo_delta && (
                        <EloChange delta={opponent.score_elo_delta} />
                      )}
                    </Group>
                  )}
                </Group>
              ))}
            </Stack>
          </>
        )}

        {/* Error Message */}
        {run.status === 'error' && run.error_message && (
          <Alert
            icon={<AlertTriangle size={16} />}
            color="red"
            title={run.error_type?.replace('_', ' ').toUpperCase()}
          >
            {run.error_message}
          </Alert>
        )}
      </Stack>
    </Card>
  );
};


const MetricRow = ({ icon, title, mean, max, progress, alert }) => (
  <Stack spacing={4}>
    <Group position="apart">
      <Group spacing="xs">
        {icon}
        <Text size="sm">{title}</Text>
      </Group>
      <Group spacing="xs">
        <Text size="sm" color="dimmed">Mean: {mean}</Text>
        <Text size="sm" color="dimmed">Max: {max}</Text>
      </Group>
    </Group>
    {progress !== undefined && (
      <Progress 
        value={progress} 
        color={alert ? "red" : "blue"}
        size="sm"
      />
    )}
  </Stack>
);

const GameOutcomeBadge = ({ outcome }) => {
  const config = {
    winner: { icon: <Trophy size={14} />, color: 'green', text: 'Winner' },
    loser: { icon: <TrendingDown size={14} />, color: 'red', text: 'Loser' },
    draw: { icon: <Activity size={14} />, color: 'yellow', text: 'Draw' }
  };

  const { icon, color, text } = config[outcome] || { color: 'gray', text: 'Unknown' };

  return (
    <Badge color={color} leftSection={icon}>
      {text}
    </Badge>
  );
};

const EloChange = ({ delta }) => {
  const isPositive = delta > 0;
  return (
    <Badge 
      color={isPositive ? 'green' : 'red'}
      leftSection={isPositive ? <TrendingUp size={14} /> : <TrendingDown size={14} />}
    >
      {isPositive ? '+' : ''}{delta} ELO
    </Badge>
  );
};

export default AgentResultsDashboard;