import React from 'react';
import { Stack, Card, Group, Text, Badge, Progress, Alert, Timeline, Divider } from '@mantine/core';
import { Activity, AlertTriangle, Clock, Cpu, HardDrive, Timer, ListOrdered, Circle, Users,  Trophy, TrendingDown, TrendingUp, Minus } from 'lucide-react';

const QueueInfo = ({ queueInfo }) => {
  if (!queueInfo) return null;

  const formatSeconds = (seconds) => {
    if (seconds < 60) return `${seconds} seconds`;
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes} min ${remainingSeconds} sec`;
  };

  return (
    <Card withBorder>
      <Stack spacing="md">
        <Group position="apart" align="center">
          <Text size="lg" weight={500}>Queue Status</Text>
          <Badge 
            color="blue"
            variant="light"
          >
            IN QUEUE
          </Badge>
        </Group>

        {queueInfo.position_in_queue && (
          <Group spacing="xs">
            <ListOrdered size={16} />
            <Text size="sm">Queue Position: {queueInfo.position_in_queue}</Text>
          </Group>
        )}

        {queueInfo.created_at_ts && (
          <Group spacing="xs">
            <Clock size={16} />
            <Text size="sm">
              Started: {new Date(queueInfo.created_at_ts).toLocaleString()}
            </Text>
          </Group>
        )}

        {queueInfo.in_queue_for_second && (
          <Group spacing="xs">
            <Activity size={16} />
            <Text size="sm">Time in Queue: {formatSeconds(queueInfo.in_queue_for_second)}</Text>
          </Group>
        )}
      </Stack>
    </Card>
  );
};
// Existing RunMetrics component remains unchanged
const RunMetrics = ({ metrics }) => {
  if (!metrics) return null;

  const formatBytes = (bytes) => {
    if (bytes < 1024) return `${bytes} B`;
    const k = 1024;
    const sizes = ['B', 'KB', 'MB', 'GB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return `${(bytes / Math.pow(k, i)).toFixed(2)} ${sizes[i]}`;
  };

  return (
    <Stack spacing="xs">
      <Group position="apart">
        <Group spacing="xs">
          <Timer size={16} />
          <Text size="sm">Action Time</Text>
        </Group>
        <Group spacing="xs">
          <Text size="sm" color="dimmed">Mean: {metrics.action_time.mean?.toFixed(3)}s</Text>
          <Text size="sm" color="dimmed">Max: {metrics.action_time.max?.toFixed(3)}s</Text>
        </Group>
      </Group>

      <Group position="apart">
        <Group spacing="xs">
          <Cpu size={16} />
          <Text size="sm">CPU Usage</Text>
        </Group>
        <Group spacing="xs">
          <Text size="sm" color="dimmed">Mean: {metrics.cpu.percent_mean?.toFixed(1)}%</Text>
          <Text size="sm" color="dimmed">Max: {metrics.cpu.percent_max?.toFixed(1)}%</Text>
        </Group>
      </Group>
      <Progress 
        value={metrics.cpu.percent_mean || 0} 
        color={metrics.cpu.percent_mean > 80 ? "red" : "blue"}
      />

      <Group position="apart">
        <Group spacing="xs">
          <HardDrive size={16} />
          <Text size="sm">Memory Usage</Text>
        </Group>
        <Group spacing="xs">
          <Text size="sm" color="dimmed">Mean: {formatBytes(metrics.ram.mean_bytes)}</Text>
          <Text size="sm" color="dimmed">Max: {formatBytes(metrics.ram.max_bytes)}</Text>
        </Group>
      </Group>
      <Progress 
        value={metrics.ram.mean_percent || 0}
        color={metrics.ram.mean_percent > 80 ? "red" : "blue"}
      />
    </Stack>
  );
};

const OpponentsList = ({ opponents, showElo }) => {
  if (!opponents || opponents.length === 0) return null;

  return (
    <Stack spacing="xs">
      <Divider my="xs" label={<Group spacing="xs"><Users size={16} />Opponents</Group>} labelPosition="left" />
      <Group spacing="md">
        {opponents.map((opponent, index) => (
          <Badge
            key={index}
            variant="dot"
            size="lg"
            color="gray"
          >
            {opponent.agent_name} ({opponent.user_name})
            {showElo && opponent.agent_elo && ` - ELO: ${opponent.agent_elo}`}
          </Badge>
        ))}
      </Group>
    </Stack>
  );
};

const GameOutcomeBadge = ({ outcome }) => {
  const getOutcomeProps = (outcome) => {
    switch (outcome) {
      case 'winner':
        return {
          icon: <Trophy size={14} className="mr-1" />,
          color: 'green',
          text: 'Winner'
        };
      case 'loser':
        return {
          icon: <TrendingDown size={14} className="mr-1" />,
          color: 'red',
          text: 'Loser'
        };
      case 'draw':
        return {
          icon: <Minus size={14} className="mr-1" />,
          color: 'yellow',
          text: 'Draw'
        };
      default:
        return {
          icon: null,
          color: 'gray',
          text: 'Incomplete'
        };
    }
  };

  const { icon, color, text } = getOutcomeProps(outcome);

  return (
    <Badge 
      color={color}
      variant="light"
      className="flex items-center"
    >
      {icon}{text}
    </Badge>
  );
};

const EloScoreChange = ({ delta }) => {
  if (!delta) return null;

  const isPositive = delta > 0;
  const Icon = isPositive ? TrendingUp : TrendingDown;

  return (
    <Group spacing="xs">
      <Icon size={16} className={isPositive ? 'text-green-500' : 'text-red-500'} />
      <Text 
        size="sm" 
        className={isPositive ? 'text-green-500' : 'text-red-500'}
      >
        {isPositive ? '+' : ''}{delta} ELO
      </Text>
    </Group>
  );
};


const RunResult = ({ result, showElo }) => {
  const getStatusColor = (status) => {
    switch (status) {
      case 'completed': return 'green';
      case 'running': return 'blue';
      case 'error': return 'red';
      default: return 'gray';
    }
  };

  return (
    <Card withBorder className="w-full">
      <Stack spacing="xs">
        <Group position="apart">
          <Group spacing="md">
            <Badge 
              color={getStatusColor(result.status)}
              variant="light"
            >
              {result.status.toUpperCase()}
            </Badge>
            {!result.is_test && result.game_outcome && (
              <GameOutcomeBadge outcome={result.game_outcome} />
            )}
          </Group>
          {!result.is_test && showElo && result.score_elo_delta && (
            <EloScoreChange delta={result.score_elo_delta} />
          )}
        </Group>

        <Group spacing="xs" grow>
          <Group spacing="xs">
            <Activity size={16} />
            <Text size="sm">Steps: {result.steps_completed || 0}</Text>
          </Group>
          <Group spacing="xs">
            <Clock size={16} />
            <Text size="sm">Reward: {result.current_reward?.toFixed(2) || 0}</Text>
          </Group>
        </Group>

        {result.metrics && <RunMetrics metrics={result.metrics} />}

        <OpponentsList opponents={result.opponents} showElo={showElo} />

        {result.status === 'error' && (
          <Alert 
            icon={<AlertTriangle size={16} />} 
            color="red" 
            title={`${result.error_type.replace('_', ' ').toUpperCase()} Error`}
          >
            {result.error_message}
          </Alert>
        )}
      </Stack>
    </Card>
  );
};

const ResultsSection = ({ title, results, showElo }) => {
  if (!results || results.length === 0) return null;

  const getTimelineColor = (result) => {
    if (result.status === 'error') return 'red';
    if (result.status === 'completed') return 'green';
    if (result.status === 'running') return 'blue';
    return 'gray';
  };

  return (
    <Card withBorder className="mt-4">
      <Stack spacing="md">
        <Text size="lg" weight={500}>{title}</Text>
        <Timeline active={results.length - 1} bulletSize={24} lineWidth={2}>
          {results.map((result, index) => (
            <Timeline.Item
              key={index}
              bullet={<Circle size={12} />}
              title={
                <Text>
                  Run {index + 1} - {new Date(result.start_time).toLocaleString()}
                </Text>
              }
              color={getTimelineColor(result)}
            >
              <RunResult result={result} showElo={showElo} />
            </Timeline.Item>
          ))}
        </Timeline>
      </Stack>
    </Card>
  );
};

const RunInfo = ({ runInfo }) => {
  if (!runInfo || !runInfo.results || runInfo.results.length === 0) return null;

  const isEloScoring = runInfo.evaluation_type === 'elo_score';
  
  const dryRuns = runInfo.results.filter(r => r.is_test);
  const rankingRuns = runInfo.results.filter(r => !r.is_test);

  return (
    <Stack spacing="md">
      <Card withBorder>
        <Group position="apart" align="center">
          <Text size="lg" weight={500}>Deployment Progress</Text>
          <Group spacing="xs">
            {runInfo.number_agent && (
              <Badge variant="outline" color="gray">
                {runInfo.number_agent} Agents
              </Badge>
            )}
            {runInfo.evaluation_type && (
              <Badge variant="outline" color="gray">
                {runInfo.evaluation_type.replace('_', ' ').toUpperCase()}
              </Badge>
            )}
            <Badge color="blue" variant="light">
              DEPLOY ID: {runInfo.deploy_id}
            </Badge>
          </Group>
        </Group>
      </Card>

      <ResultsSection 
        title="Dry Runs" 
        results={dryRuns}
        showElo={isEloScoring} 
      />

      <ResultsSection 
        title="Initial Ranking Runs" 
        results={rankingRuns}
        showElo={isEloScoring} 
      />
    </Stack>
  );
};

  export { RunInfo, RunMetrics, QueueInfo, OpponentsList };