import React from 'react';
import { Box, Text, Group, Grid, Stack } from '@mantine/core';
import { Rocket } from 'lucide-react';

const WeeklyDeployStatus = ({ weeklyDeploysCount, weeklyDeploysLimit, weeklyDeploysRemaining }) => {
  const renderRockets = (count) => {
    return Array(count).fill(0).map((_, i) => (
      <Rocket key={i} size={20} className={i < weeklyDeploysCount ? 'text-blue-500' : 'text-gray-300'} />
    ));
  };

  return (
    <Stack spacing="xl" className="mb-6">
      <Grid align="center" justify="center" gutter="lg">
        <Grid.Col span="content">
          <Box className="bg-blue-50 p-4 rounded-lg shadow-sm text-center">
            <Text size="lg" weight={600}>Used This Week</Text>
            <Group position="center">
              {renderRockets(weeklyDeploysCount)}
            </Group>
            <Text className="mt-2 text-xl font-bold">
              {weeklyDeploysCount}
            </Text>
          </Box>
        </Grid.Col>

        <Grid.Col span="content">
          <Text align="center" size="xl" weight={700}>+</Text>
        </Grid.Col>

        <Grid.Col span="content">
          <Box className="bg-green-50 p-4 rounded-lg shadow-sm text-center">
            <Text size="lg" weight={600}>Remaining</Text>
            <Group position="center">
              {renderRockets(weeklyDeploysRemaining)}
            </Group>
            <Text className="mt-2 text-xl font-bold">
              {weeklyDeploysRemaining}
            </Text>
          </Box>
        </Grid.Col>

        <Grid.Col span="content">
          <Text align="center" size="xl" weight={700}>=</Text>
        </Grid.Col>

        <Grid.Col span="content">
          <Box className="bg-gray-50 p-4 rounded-lg shadow-sm text-center">
            <Text size="lg" weight={600}>Weekly Deploy Limit</Text>
            <Group position="center">
              {renderRockets(weeklyDeploysLimit)}
            </Group>
            <Text className="mt-2 text-xl font-bold">
              {weeklyDeploysLimit}
            </Text>
          </Box>
        </Grid.Col>
      </Grid>
    </Stack>
  );
};

export default WeeklyDeployStatus;