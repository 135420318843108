import React from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Button,
  Stack,
  Text,
  Title,
  Container,
  Paper,
  Group,
} from '@mantine/core';
import { IconBrandGoogle, IconBrandGithub, IconMail } from '@tabler/icons-react';
import { useAuth } from 'context/AuthContext';
import axios from 'axios';

const Login = () => {
  const navigate = useNavigate();
  const { setIsAuthenticated, setCurrentUser } = useAuth();

  const handleSocialLogin = (provider) => {
    const width = 600;
    const height = 600;
    const left = window.screen.width / 2 - width / 2;
    const top = window.screen.height / 2 - height / 2;
  
    const authWindow = window.open(
      `https://rlarena.com/api/auth/login/${provider}`,
      'GoogleAuth',
      `width=${width},height=${height},top=${top},left=${left}`
    );
  
    const timer = setInterval(() => {
      if (authWindow.closed) {
        clearInterval(timer);
        axios.get('/api/auth/current_user')
          .then((response) => {
            if (response.data.isAuthenticated) {
              setIsAuthenticated(true);
              setCurrentUser({ username: response.data.username });
              navigate('/listcompetition');
            } else {
              alert('Authentication failed or was canceled.');
            }
          })
          .catch((error) => {
            console.error('Error checking auth status', error);
          });
      }
    }, 1000);
  };

  return (
    <Container size={420} my={40}>
      <Title align="center" sx={(theme) => ({ fontFamily: `Greycliff CF, ${theme.fontFamily}`, fontWeight: 900 })}>
        Welcome!
      </Title>

      <Paper withBorder shadow="md" p={30} mt={30} radius="md">
        <Stack spacing="md">
          <Button 
            onClick={() => handleSocialLogin('google')}
            variant="default"
            fullWidth
          >
            <Group spacing="sm">
              <IconBrandGoogle size={18} />
              <Text>Sign in/up with Google</Text>
            </Group>
          </Button>
          <Button 
            onClick={() => handleSocialLogin('github')}
            variant="default"
            fullWidth
          >
            <Group spacing="sm">
              <IconBrandGithub size={18} />
              <Text>Sign in/up with GitHub</Text>
            </Group>
          </Button>
          <Button 
            onClick={() => navigate('/login-mail/sign-in')}
            variant="default"
            fullWidth
          >
            <Group spacing="sm">
              <IconMail size={18} />
              <Text>Sign in/up with Email</Text>
            </Group>
          </Button>
        </Stack>

        <Text color="dimmed" size="sm" align="center" mt={20}>
          By signing in, you agree to our Terms of Service and Privacy Policy.
        </Text>
      </Paper>
    </Container>
  );
};

export default Login;