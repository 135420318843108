import React, { useEffect } from 'react';
import { Card, Text, Group, Button, NumberInput, Stack } from '@mantine/core';
import { IconChevronUp, IconChevronDown, IconFlag, IconPlayerPlay } from '@tabler/icons-react';
import useAgentAllocation from 'hooks/DirectAgentAttach/useAgentAllocation';

const AllocationControl = ({ 
  attacheAgentId, 
  isStarted, 
  handleStart, 
  isStartBlocked 
}) => {
  const {
    allocation: dailyRunAllocation,
    availableAllocation,
    totalLimit: dailyAgentRunLimit,
    updateAllocation,
    isUpdating,
    fetchAllocation
  } = useAgentAllocation(attacheAgentId);

  // Fetch initial allocation data
  useEffect(() => {
    fetchAllocation();
  }, [attacheAgentId]);

  // State for the new allocation value being edited
  const [newDailyRunAllocation, setNewDailyRunAllocation] = React.useState(dailyRunAllocation || 0);

  // Update local state when allocation data is loaded
  useEffect(() => {
    if (dailyRunAllocation !== null) {
      setNewDailyRunAllocation(dailyRunAllocation);
    }
  }, [dailyRunAllocation]);

  const isAllocationChanged = newDailyRunAllocation !== dailyRunAllocation;

  const handleAllocationUpdate = async () => {
    if (isAllocationChanged) {
      const success = await updateAllocation(newDailyRunAllocation);
      if (success) {
        handleStart?.(); // Call handleStart only if it exists and update was successful
      }
    }
  };

  const getActionButton = () => {
    if (!isStarted && newDailyRunAllocation > 0) {
      return (
        <Button
          color="green"
          onClick={handleStart}
          disabled={isStartBlocked || isUpdating}
          fullWidth
          leftIcon={<IconFlag size={14} />}
        >
          Start Competition
        </Button>
      );
    } else if (isStarted && isAllocationChanged) {
      return (
        <Button
          color="blue"
          onClick={handleAllocationUpdate}
          disabled={!isAllocationChanged || isUpdating}
          fullWidth
          leftIcon={<IconPlayerPlay size={14} />}
          loading={isUpdating}
        >
          Update Allocation
        </Button>
      );
    }
    return null;
  };

  return (
    <Card shadow="sm" p="lg" radius="md" withBorder>
      <Stack>
        <Text size="sm" weight={500}>Set Daily Run Allocation</Text>
        <Group position="apart" align="center">
          <NumberInput
            value={newDailyRunAllocation}
            onChange={(value) => setNewDailyRunAllocation(value)}
            min={0}
            max={dailyAgentRunLimit}
            step={1}
            styles={{ input: { width: 60, textAlign: 'center' } }}
            rightSection={
              <Stack spacing={0}>
                <Button
                  compact
                  variant="subtle"
                  size="xs"
                  onClick={() => setNewDailyRunAllocation(Math.min(
                    newDailyRunAllocation + 1, 
                    dailyRunAllocation + availableAllocation
                  ))}
                  disabled={newDailyRunAllocation >= dailyRunAllocation + availableAllocation}
                >
                  <IconChevronUp size={14} />
                </Button>
                <Button
                  compact
                  variant="subtle"
                  size="xs"
                  onClick={() => setNewDailyRunAllocation(Math.max(newDailyRunAllocation - 1, 0))}
                  disabled={newDailyRunAllocation <= 0}
                >
                  <IconChevronDown size={14} />
                </Button>
              </Stack>
            }
            rightSectionWidth={30}
            disabled={isUpdating}
          />
          <Stack spacing={0} align="flex-end">
            {isAllocationChanged && (
              <Text size="xs" color="dimmed">Previous: {dailyRunAllocation}</Text>
            )}
            <Text size="xs" color="dimmed">Available: {availableAllocation}</Text>
          </Stack>
        </Group>
        {getActionButton()}
      </Stack>
    </Card>
  );
};

export default AllocationControl;