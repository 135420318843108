// useCompetitionData.js
import { useState, useEffect, useCallback } from "react";
import {
  fetchCompetition,
  saveCompetition,
  deleteCompetition,
  fetchAttachments,
} from "services/apiService";

const initialCompetitionState = {
  name: '',
  description: '',
  start_date_ts: '',
  end_date_ts: '',
  created_at_ts: '',
  is_started: false,
  programmed_end_at_ts: '',
  user_id: '',
  is_public: false
};

export const useCompetitionData = (competitionId) => {
  const [competitionData, setCompetitionData] = useState(initialCompetitionState);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [attachmentIds, setAttachments] = useState({
    configurationId: null,
    environmentId: null,
  });

  useEffect(() => {
    let isMounted = true;

    const fetchAllData = async () => {
      if (!competitionId) {
        setCompetitionData(initialCompetitionState);
        setLoading(false);
        return;
      }

      try {
        const [compData, attachmentData] = await Promise.all([
          fetchCompetition(competitionId),
          fetchAttachments(competitionId)
        ]);

        if (!isMounted) return;

        setCompetitionData(compData || initialCompetitionState);
        setAttachments({
          configurationId: attachmentData?.configurationId || null,
          environmentId: attachmentData?.environmentId || null,
        });
      } catch (error) {
        if (isMounted) {
          setError(error);
          setCompetitionData(initialCompetitionState);
        }
      } finally {
        if (isMounted) {
          setLoading(false);
        }
      }
    };

    fetchAllData();

    return () => {
      isMounted = false;
    };
  }, [competitionId]);

  const updateCompetition = useCallback((updatedData) => {
    setCompetitionData(prevData => ({
      ...prevData,
      ...updatedData
    }));
  }, []);

  const handleSaveCompetition = useCallback(async (data) => {
    setLoading(true);
    try {
      const responseData = await saveCompetition(competitionId, data);
      setCompetitionData(prevData => ({
        ...prevData,
        ...responseData
      }));
      return responseData;
    } catch (error) {
      setError(error);
      throw error;
    } finally {
      setLoading(false);
    }
  }, [competitionId]);

  const handleDeleteCompetition = useCallback(async () => {
    setLoading(true);
    try {
      await deleteCompetition(competitionId);
      setCompetitionData(initialCompetitionState);
      setAttachments({
        configurationId: null,
        environmentId: null,
      });
    } catch (error) {
      setError(error);
      throw error;
    } finally {
      setLoading(false);
    }
  }, [competitionId]);

  return {
    competitionData,
    loading,
    error,
    updateCompetition,
    handleSaveCompetition,
    handleDeleteCompetition,
    ...attachmentIds
  };
};

export default useCompetitionData;