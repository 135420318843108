// authService.js
import axios from 'axios';

const API_URL = '/api/auth'; // Adjust this to match your API base URL

const authService = {
  login: async (username, password, recaptchaToken) => {
    try {
      const response = await axios.post(`${API_URL}/login`, { username, password, recaptchaToken });
      if (response.data.message === 'Login successful') {
        // You might want to store the token in localStorage here
        // localStorage.setItem('token', response.data.token);
        return response.data;
      } else {
        throw new Error(response.data.message || 'Login failed');
      }
    } catch (error) {
      throw new Error(error.response?.data?.message || 'An error occurred during login');
    }
  },

  register: async (username, email, password) => {
    try {
      const response = await axios.post(`${API_URL}/register`, { username, email, password });
      return {
        success: true,
        message: response.data.message,
      };
    } catch (error) {
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        if (error.response.status === 409) {
          return {
            success: false,
            message: error.response.data.message,
            field: error.response.data.message.includes('Username') ? 'username' : 'email',
          };
        }
        return {
          success: false,
          message: error.response.data.message || 'An error occurred during registration.',
        };
      }
      // Network error or other issues
      return {
        success: false,
        message: 'An error occurred during registration. Please try again later.',
      };
    }
  },

  forgotPassword: async (email) => {
    try {
      const response = await axios.post(`${API_URL}/forgot-password`, { email });
      return response.data;
    } catch (error) {
      throw new Error(error.response?.data?.message || 'An error occurred while processing your request');
    }
  },

  resetPassword: async (token, newPassword) => {
    try {
      const response = await axios.post(`${API_URL}/reset-password`, { token, newPassword });
      return response.data;
    } catch (error) {
      throw new Error(error.response?.data?.message || 'An error occurred while resetting your password');
    }
  },

  logout: async () => {
    try {
      const response = await axios.post(`${API_URL}/logout`);
      // Clear any stored authentication tokens
      // localStorage.removeItem('token');
      return response.data;
    } catch (error) {
      throw new Error(error.response?.data?.message || 'An error occurred during logout');
    }
  },

  getCurrentUser: async () => {
    try {
      const response = await axios.get(`${API_URL}/current_user`);
      return response.data;
    } catch (error) {
      throw new Error(error.response?.data?.message || 'An error occurred while fetching user data');
    }
  },
};

export default authService;