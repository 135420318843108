import { useState, useEffect } from 'react';
import axios from 'axios';

export const useRuntimeOptions = (competitionId, isFetchAll=true, agentAttachedId = null) => {
  const [runtimeState, setRuntimeState] = useState({
    options: {},
    selectedRuntime: null,
    isLoading: true,
    error: null,
    isUpdating: false
  });

  useEffect(() => {
    const fetchRuntimeOptions = async () => {
      try {
        setRuntimeState(prev => ({ ...prev, isLoading: true, error: null }));

        let response;
        if (isFetchAll === false && agentAttachedId) {
          // Fetch specific agent runtime configuration
          response = await axios.get(`/api/direct_attache_agents/agent_runtime/${agentAttachedId}`);
          setRuntimeState(prev => ({
            ...prev,
            options: response.data,
            selectedRuntime: response.data.currentRuntime || null,
            isLoading: false
          }));
        } else {
          // Fetch general runtime options
          response = await axios.get(`/api/direct_attache_agents/runtime_options/${competitionId}`);
          setRuntimeState(prev => ({
            ...prev,
            options: response.data,
            isLoading: false
          }));
        }
      } catch (error) {
        setRuntimeState(prev => ({
          ...prev,
          error: error.response?.data?.error || 'Failed to fetch runtime options',
          isLoading: false
        }));
      }
    };

    if (competitionId) {
      fetchRuntimeOptions();
    }
  }, [competitionId, agentAttachedId]);


  const setSelectedRuntime = async (runtimeId) => {
    if (!agentAttachedId || !runtimeId) {
      return;
    }
  
    if (!Number.isInteger(runtimeId)) {
      setRuntimeState(prev => ({
        ...prev,
        selectedRuntime: runtimeId
      }));
      return;
    }
  
    try {
      setRuntimeState(prev => ({
        ...prev,
        isUpdating: true,
        error: null
      }));
  
      console.log(runtimeId);
  
      // Update runtime on the server
      const response = await  axios.put(`/api/direct_attache_agents/agent_runtime/${agentAttachedId}`, {
        docker_image_agent_runtime_id: runtimeId
      });
  
    } catch (error) {
      setRuntimeState(prev => ({
        ...prev,
        error: error.response?.data?.error || 'Failed to update runtime',
        isUpdating: false
      }));
      console.error('Error updating runtime:', error);
    }
  };
  


  return {
    ...runtimeState,
    setSelectedRuntime
  };
};