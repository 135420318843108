import React from 'react';
import { Group, Text, Stack } from '@mantine/core';
import { IconServer, IconCpu, IconClock } from '@tabler/icons-react';

const CompetitionConstraints = ({ memoryLimit, cpuLimit, maxTimePerStep }) => {
  const formatMemory = (value) => {
    const match = value.match(/(\d+)(\w+)/);
    if (!match) return value;
    const [, num, unit] = match;
    return `${num} ${unit.toUpperCase()}`;
  };

  const formatCpu = (value) => {
    const numValue = parseInt(value);
    if (isNaN(numValue)) return value;
    return `${numValue / 1000} cores`;
  };

  const formatTime = (value) => {
    const numValue = parseFloat(value);
    if (isNaN(numValue)) return value;
    return `${(numValue * 1000).toFixed(0)} ms`;
  };

  return (
    <Stack align="flex-end" w="100%">
      <Group position="right" spacing="xl">
        <Stack align="center" spacing="xs">
          <IconServer size={24} />
          <Text size="sm" weight={500}>Memory Limit</Text>
          <Text>{formatMemory(memoryLimit)}</Text>
        </Stack>
        <Stack align="center" spacing="xs">
          <IconCpu size={24} />
          <Text size="sm" weight={500}>CPU Limit</Text>
          <Text>{formatCpu(cpuLimit)}</Text>
        </Stack>
        <Stack align="center" spacing="xs">
          <IconClock size={24} />
          <Text size="sm" weight={500}>Max Time per Step</Text>
          <Text>{formatTime(maxTimePerStep)}</Text>
        </Stack>
      </Group>
    </Stack>
  );
};

export default CompetitionConstraints;