// VideoPlayer.js
import React from "react";
import { Image, Loader, Center, Box } from "@mantine/core";

const VideoPlayer = ({ imageData, isLoading, isPlaying }) => (
  <Box w={400} h={300} style={{ overflow: "hidden" }}>
    {isLoading && !isPlaying ? (
      <Center style={{ height: "100%" }}>
        <Loader size="xl" />
      </Center>
    ) : (
      <Image
        src={imageData}
        alt="Live Stream"
        fit="contain"
        height={300}
        width={400}
      />
    )}
  </Box>
);

export default VideoPlayer;