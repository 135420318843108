import React, { useState, useRef, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Grid, LoadingOverlay, Box, Card, Group, Text } from '@mantine/core';
import { Trophy } from 'lucide-react';
import { useVideoStream } from "hooks/useVideoStream";
import VideoFileList from "components/Competition/VideoFileList";
import VideoDisplay from "components/Competition/VideoDisplay";

const LiveStream = ({ renderDelay }) => {
  const { competitionId } = useParams();
  const [selectedFileName, setSelectedFileName] = useState([null, null]);
  const { socket_current, isConnected } = useVideoStream(competitionId);
  const containerRef = useRef(null);
  const [containerHeight, setContainerHeight] = useState('600px');

  useEffect(() => {
    const updateHeight = () => {
      if (containerRef.current) {
        setContainerHeight(containerRef.current.offsetHeight + 'px');
      }
    };

    updateHeight();
    window.addEventListener('resize', updateHeight);
    return () => window.removeEventListener('resize', updateHeight);
  }, []);

  const selectVideo = (fileName) => {
    setSelectedFileName(fileName);
  };

  return (
    <Box pos="relative">
      <LoadingOverlay visible={!isConnected} overlayBlur={2} />
      {isConnected && (
        <>
          <Group position="apart" mb="md">
            <Group>
              <Trophy size={24} className="text-yellow-500" />
              <Text size="xl" fw={700}>Best Agent Recent Runs</Text>
            </Group>
          </Group>
          
          <Card shadow="sm" radius="md" withBorder className="mb-4">
            <Grid gutter={0} ref={containerRef} style={{ minHeight: '600px' }}>
              <Grid.Col span={8} style={{ height: containerHeight }}>
                <VideoDisplay
                  socket_current={socket_current}
                  fileName={selectedFileName[0]}
                  fileNameInfo={selectedFileName[1]}
                  renderDelay={renderDelay}
                />
              </Grid.Col>
              <Grid.Col 
                span={4} 
                style={{ 
                  borderLeft: '1px solid #e9ecef',
                  height: containerHeight,
                  overflow: 'hidden'
                }}
              >
                <VideoFileList
                  socket_current={socket_current}
                  competitionId={competitionId}
                  onSelectVideo={selectVideo}
                  selectedVideo={selectedFileName}
                />
              </Grid.Col>
            </Grid>
          </Card>
        </>
      )}
    </Box>
  );
};

export default LiveStream;