import React from 'react';
import { Button, Modal, Group, Text } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { IconTrash } from '@tabler/icons-react';
import { useNavigate } from 'react-router-dom';

const DeleteAttach = ({ onDelete }) => {
  const [opened, { open, close }] = useDisclosure(false);
  const navigate = useNavigate();

  const handleDelete = () => {
    close();
    onDelete();
    navigate('/listcompetition');
  };

  return (
    <>
      <Button
        variant="outline"
        color="red"
        size="sm"
        leftIcon={<IconTrash size="1rem" />}
        onClick={open}
      >
        Delete Agent
      </Button>

      <Modal opened={opened} onClose={close} title="Confirm Agent Deletion" centered>
        <Text size="sm" mb="xl">
          Are you sure you want to delete this agent?
        </Text>
        <Group position="right">
          <Button variant="default" onClick={close}>
            Cancel
          </Button>
          <Button color="red" onClick={handleDelete}>
          Delete Agent
          </Button>
        </Group>
      </Modal>
    </>
  );
};

export default DeleteAttach;