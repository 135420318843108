import React, { useState, useEffect } from 'react';
import { Badge, Stack, Card, Group, Text, Progress, Alert } from '@mantine/core';
import FileManagement from 'components/DirectAgentAttach/Upload/FileManagement';
import RuntimeSelector from 'components/DirectAgentAttach/RuntimeSelector';
import AgentInstructions from 'components/DirectAgentAttach/Upload/AgentInstructions';
import { useRuntimeOptions } from 'hooks/DirectAgentAttach/useRuntimeOptions';
import { useAgentFileManagement } from 'hooks/DirectAgentAttach/useAgentFileManagement';
import { Check, X } from 'lucide-react';

const StatusBadge = ({ status }) => {
  const statusColors = {
    created: 'blue',
    uploading: 'yellow',
    upload_failed: 'red',
    upload_validated: 'green',
    deploying: 'yellow',
    deploy_failed: 'red',
    active: 'green'
  };

  return (
    <Badge color={statusColors[status] || 'gray'} variant="filled">
      {status.replace('_', ' ').toUpperCase()}
    </Badge>
  );
};

const MessageAlert = ({ status, message }) => {
  // Map status to message type and color
  const getMessageConfig = (status) => {
    switch (status) {
      case 'upload_validated':
        return { type: 'success', color: 'green' };
      case 'upload_failed':
      case 'deploy_failed':
        return { type: 'error', color: 'red' };
      case 'uploading':
      case 'deploy_queue':
        return { type: 'info', color: 'blue' };
      default:
        return null;
    }
  };
 
  const config = getMessageConfig(status);
  if (!config || !message) return null;
 
  return (
    <Alert
      icon={config.type === 'error' ? <X size={16} /> : <Check size={16} />}
      color={config.color}
      variant="light"
      title={status.replace('_', ' ').toUpperCase()}
    >
      {message}
    </Alert>
  );
 };

const AgentUpload = ({ competitionId, attacheAgentId, status, statusMessage, onStatusChange }) => {
  const { fileState, handlers, handleAgentCodeChange } = useAgentFileManagement(
    competitionId, 
    attacheAgentId,
    onStatusChange,
  );
  const {
    options: runtimeOptions,
    selectedRuntime,
    setSelectedRuntime,
    isLoading: isLoadingRuntime
  } = useRuntimeOptions(competitionId, true, attacheAgentId);
 
  const UPLOAD_PHASE_STATUSES = ['created', 'uploading', 'upload_failed', 'upload_validated', 'deploy_failed'];
  const isInUploadPhase = UPLOAD_PHASE_STATUSES.includes(status);
 
  return (
    <Stack spacing="xl">
     <MessageAlert 
       status={status}
       message={statusMessage}
     />
      <AgentInstructions />
      
      <Card withBorder>
        <Stack spacing="md">
          <Group position="apart">
            <Text weight={500}>Agent Configuration</Text>
            <StatusBadge status={status} />
          </Group>
          
          {status === 'uploading' && (
            <Progress value={75} label="Uploading..." size="xl" radius="xl" striped animated />
          )}
 
          <RuntimeSelector
            isLoading={isLoadingRuntime}
            runtimeOptions={runtimeOptions}
            setSelectedImage={setSelectedRuntime}
            isReadOnly={!isInUploadPhase}
          />
        </Stack>
      </Card>
 
      {fileState.error && (
        <Alert color="red" title="Error">{fileState.error}</Alert>
      )}
 
      <FileManagement
        files={fileState.files}
        loading={fileState.isUploading}
        disabled={!isInUploadPhase}
        onDelete={handlers.handleFileDelete}
        onEdit={handlers.handleEditFile}
        onUpload={handlers.handleUpload}
        agentCode={fileState.agentCode}
        setagentCode={handleAgentCodeChange}
      />
    </Stack>
  );
 };

export default AgentUpload;