// frontend/src/pages/Agent/Edit.js
import React from "react";
import RepositoryManager from "components/Repository/RepositoryManager";

const EditAgent = () => {

  return (
    <div>
      <RepositoryManager basePath="agent" />
    </div>
  );
};

export default EditAgent;
