import React, { useState, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Card, Text, Badge, Button, Group, Stack, Modal, LoadingOverlay, Progress,
  Box, Select, TextInput, Grid, Paper, ActionIcon, Tooltip
} from '@mantine/core';
import { IconTrophy, IconTrash, IconPlayerPlay, IconPlayerPause, IconSearch, IconSettings, IconAlertTriangle } from '@tabler/icons-react';
import useMyAgents from 'hooks/useMyAgents';
import MaxAgentsModal from 'components/MyAgents/MaxAgentsModal';
import AllocationControlMyAgents from 'components/MyAgents/AllocationControlMyAgents';
import DailyRunAllocation from 'components/MyAgents/DailyRunAllocation';
import WeeklyDeployStatus from 'components/MyAgents/WeeklyDeployStatus';

// AgentStatus enum to match backend
const AgentStatus = {
  CREATED: 'created',
  UPLOADING: 'uploading',
  UPLOAD_FAILED: 'upload_failed',
  UPLOAD_VALIDATED: 'upload_validated',
  DEPLOYING: 'deploying',
  DEPLOY_QUEUE: 'deploy_queue',
  DEPLOY_RUN: 'deploy_run',
  DEPLOY_FAILED: 'deploy_failed',
  ACTIVE: 'active',
  PAUSED: 'paused'
};

const getStatusBadgeProps = (status, isStarted) => {
  const statusConfig = {
    [AgentStatus.CREATED]: { color: 'gray', icon: <IconSettings size={14} />, label: 'Created' },
    [AgentStatus.UPLOADING]: { color: 'blue', icon: <IconPlayerPlay size={14} />, label: 'Uploading' },
    [AgentStatus.UPLOAD_FAILED]: { color: 'red', icon: <IconAlertTriangle size={14} />, label: 'Upload Failed' },
    [AgentStatus.UPLOAD_VALIDATED]: { color: 'teal', icon: <IconPlayerPause size={14} />, label: 'Ready' },
    [AgentStatus.DEPLOYING]: { color: 'indigo', icon: <IconPlayerPlay size={14} />, label: 'Deploying' },
    [AgentStatus.DEPLOY_QUEUE]: { color: 'violet', icon: <IconPlayerPause size={14} />, label: 'In Queue' },
    [AgentStatus.DEPLOY_RUN]: { color: 'grape', icon: <IconPlayerPlay size={14} />, label: 'Testing' },
    [AgentStatus.DEPLOY_FAILED]: { color: 'red', icon: <IconAlertTriangle size={14} />, label: 'Deploy Failed' },
    [AgentStatus.ACTIVE]: { color: 'green', icon: <IconPlayerPlay size={14} />, label: 'Active' },
    [AgentStatus.PAUSED]: { color: 'orange', icon: <IconPlayerPause size={14} />, label: 'Paused' }
  };

  return statusConfig[status] || { color: 'gray', icon: <IconSettings size={14} />, label: 'Unknown' };
};

const ConfirmationModal = ({ opened, onClose, onConfirm, title, body }) => (
  <Modal opened={opened} onClose={onClose} title={title}>
    <Text>{body}</Text>
    <Group position="right" mt="md">
      <Button variant="default" onClick={onClose}>Cancel</Button>
      <Button color="red" onClick={onConfirm}>Confirm</Button>
    </Group>
  </Modal>
);

const MyAgents = () => {
  const {
    subscriptions,
    dailyAgentRunLimit,
    totalDailyRunAllocation,
    weeklyDeploysCount,
    weeklyDeploysLimit,
    weeklyDeploysRemaining,
    loading,
    error,
    toggleSubscriptionActiveState,
    deleteSubscription,
  } = useMyAgents();

  const navigate = useNavigate();
  const [confirmationModal, setConfirmationModal] = useState({ opened: false, subscription: null, action: '' });
  const [maxAgentsModal, setMaxAgentsModal] = useState(false);
  const [filters, setFilters] = useState({ competition: '', allocation: '', status: '' });
  const [search, setSearch] = useState('');

  const filteredSubscriptions = useMemo(() => {
    return subscriptions.filter(sub => {
      const matchesSearch = search === '' || 
        sub.competition_name.toLowerCase().includes(search.toLowerCase()) ||
        sub.agent_name.toLowerCase().includes(search.toLowerCase());
      
      const matchesCompetition = !filters.competition || sub.competition_name === filters.competition;
      
      const matchesAllocation = !filters.allocation || 
        (filters.allocation === 'none' && sub.daily_run_allocation === 0) ||
        (filters.allocation === 'low' && sub.daily_run_allocation > 0 && sub.daily_run_allocation < 5) ||
        (filters.allocation === 'medium' && sub.daily_run_allocation >= 5 && sub.daily_run_allocation < 15) ||
        (filters.allocation === 'high' && sub.daily_run_allocation >= 15);
      
      const matchesStatus = !filters.status || sub.status === filters.status;
      
      return matchesSearch && matchesCompetition && matchesAllocation && matchesStatus;
    });
  }, [subscriptions, filters, search]);

  const uniqueCompetitions = useMemo(() => 
    [{value: '', label: 'All Competitions'}, 
     ...Array.from(new Set(subscriptions.map(sub => sub.competition_name)))
       .map(name => ({value: name, label: name}))
    ],
    [subscriptions]
  );

  const allocationOptions = [
    { value: '', label: 'All Allocations' },
    { value: 'none', label: 'No Allocation' },
    { value: 'low', label: 'Low (1-4)' },
    { value: 'medium', label: 'Medium (5-14)' },
    { value: 'high', label: 'High (15+)' },
  ];

  const statusOptions = [
    { value: '', label: 'All Statuses' },
    { value: AgentStatus.ACTIVE, label: 'Active' },
    { value: AgentStatus.PAUSED, label: 'Paused' },
    { value: AgentStatus.UPLOAD_VALIDATED, label: 'Ready' },
    { value: AgentStatus.DEPLOYING, label: 'Deploying' },
    { value: AgentStatus.DEPLOY_FAILED, label: 'Failed' },
  ];

  const handleUpdateAllocation = (attacheAgentId, newAllocation) => {
    const subscription = subscriptions.find(sub => sub.id === attacheAgentId);
    if (subscription.status !== AgentStatus.ACTIVE &&
        dailyAgentRunLimit - totalDailyRunAllocation + subscription.daily_run_allocation - newAllocation < 0) {
      setMaxAgentsModal(true);
      return;
    }
    toggleSubscriptionActiveState(attacheAgentId, newAllocation);
  };

  if (error) {
    return (
      <Paper p="md" withBorder>
        <Group>
          <IconAlertTriangle size={24} color="red" />
          <Text color="red">Error loading agents: {error}</Text>
        </Group>
      </Paper>
    );
  }

  return (
    <Stack spacing="xl">
      <LoadingOverlay visible={loading} />
      
      <WeeklyDeployStatus 
        weeklyDeploysCount={weeklyDeploysCount}
        weeklyDeploysLimit={weeklyDeploysLimit}
        weeklyDeploysRemaining={weeklyDeploysRemaining}
      />
      
      <DailyRunAllocation 
        dailyAgentRunLimit={dailyAgentRunLimit} 
        totalDailyRunAllocation={totalDailyRunAllocation}
      />

      <Paper shadow="xs" p="md" radius="md">
        <Grid align="center" gutter="md">
          <Grid.Col span={6}>
            <TextInput
              icon={<IconSearch size={14} />}
              placeholder="Search agents or competitions..."
              value={search}
              onChange={(event) => setSearch(event.currentTarget.value)}
            />
          </Grid.Col>
          <Grid.Col span={2}>
            <Select
              data={uniqueCompetitions}
              placeholder="Competition"
              value={filters.competition}
              onChange={(value) => setFilters({ ...filters, competition: value })}
              clearable
            />
          </Grid.Col>
          <Grid.Col span={2}>
            <Select
              data={allocationOptions}
              placeholder="Allocation"
              value={filters.allocation}
              onChange={(value) => setFilters({ ...filters, allocation: value })}
              clearable
            />
          </Grid.Col>
          <Grid.Col span={2}>
            <Select
              data={statusOptions}
              placeholder="Status"
              value={filters.status}
              onChange={(value) => setFilters({ ...filters, status: value })}
              clearable
            />
          </Grid.Col>
        </Grid>
      </Paper>

      <Grid gutter="md">
        {filteredSubscriptions.length > 0 ? (
          filteredSubscriptions.map((subscription) => {
            const statusProps = getStatusBadgeProps(subscription.status);
            
            return (
              <Grid.Col key={subscription.id} span={6}>
                <Card shadow="sm" padding="lg" radius="md" withBorder>
                  <Group position="apart" mb="xs">
                    <Badge
                      color={statusProps.color}
                      leftSection={statusProps.icon}
                    >
                      {statusProps.label}
                    </Badge>
                    {subscription.last_status_message && (
                      <Tooltip label={subscription.last_status_message}>
                        <IconAlertTriangle size={16} />
                      </Tooltip>
                    )}
                  </Group>

                  <Group position="apart" mb="md">
                    <Text size="xl" weight={700}>{subscription.agent_name}</Text>
                    <Button 
                      variant="light"
                      onClick={() => navigate(`/attach-agent/${subscription.competition_id}/${subscription.id}`)}
                    >
                      Manage Agent
                    </Button>
                  </Group>

                  <Group position="apart" mb="md">
                    <Group spacing="xs">
                      <IconTrophy size={18} />
                      <Text>{subscription.rank || 'N/A'}</Text>
                      <Text>{subscription.competition_name}</Text>
                    </Group>
                    <Button 
                      variant="light"
                      onClick={() => navigate(`/viewcompetition/${subscription.competition_id}`)}
                    >
                      View Competition
                    </Button>
                  </Group>

                  <AllocationControlMyAgents
                    subscription={subscription}
                    dailyAgentRunLimit={dailyAgentRunLimit}
                    totalDailyRunAllocation={totalDailyRunAllocation}
                    onUpdate={handleUpdateAllocation}
                    isStartBlocked={subscription.status === AgentStatus.DEPLOYING || 
                                  subscription.status === AgentStatus.DEPLOY_QUEUE}
                  />

                  <Box ml="auto" mt="md">
                    <Tooltip label="Delete Agent">
                      <ActionIcon 
                        color="red" 
                        variant="subtle" 
                        onClick={() => setConfirmationModal({ 
                          opened: true, 
                          subscription, 
                          action: 'delete' 
                        })}
                      >
                        <IconTrash size={18} />
                      </ActionIcon>
                    </Tooltip>
                  </Box>
                </Card>
              </Grid.Col>
            );
          })
        ) : (
          <Grid.Col>
            <Text align="center" color="dimmed">
              No agents found matching the current filters.
            </Text>
          </Grid.Col>
        )}
      </Grid>

      <ConfirmationModal
        opened={confirmationModal.opened}
        onClose={() => setConfirmationModal({ opened: false, subscription: null, action: '' })}
        onConfirm={() => {
          deleteSubscription(confirmationModal.subscription.id);
          setConfirmationModal({ opened: false, subscription: null, action: '' });
        }}
        title="Confirm Deletion"
        body={`Are you sure you want to delete the agent "${confirmationModal.subscription?.agent_name}"?`}
      />

      <MaxAgentsModal opened={maxAgentsModal} onClose={() => setMaxAgentsModal(false)} />
    </Stack>
  );
};

export default MyAgents;