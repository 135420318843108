import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Container, SimpleGrid, Card, Text, Button, Group, TextInput, Badge, Image, AspectRatio } from '@mantine/core';
import { IconPlus, IconSearch, IconUsers, IconClock } from '@tabler/icons-react';
import { useAuth } from 'context/AuthContext';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';

dayjs.extend(relativeTime);

const ListCompetition = () => {
  const [competitions, setCompetitions] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const navigate = useNavigate();
  const { isAdmin } = useAuth();

  useEffect(() => {
    const fetchCompetitions = async () => {
      const response = await fetch(`${process.env.REACT_APP_PREFIX_BACKEND}/api/competitions/`, {
        credentials: 'include',
      });
      const data = await response.json();
      setCompetitions(data);
    };

    fetchCompetitions();
  }, []);

  const handleNavigate = (competitionId) => {
    navigate(`/viewcompetition/${competitionId}`);
  };

  const filteredCompetitions = competitions.filter(comp => 
    comp.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
    comp.description.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const getTimeRemaining = (endTime) => {
    if (!endTime) return 'No end time set';
    const now = dayjs();
    const end = dayjs(endTime);
    if (end.isBefore(now)) return 'Ended';
    return end.from(now, true) + ' left';
  };

  return (
    <Container size="xl" py="xl">
      <Group position="apart" mb="xl">
        <TextInput
          placeholder="Search competitions..."
          icon={<IconSearch size={14} />}
          value={searchTerm}
          onChange={(event) => setSearchTerm(event.currentTarget.value)}
          style={{ width: '300px' }}
        />
        {isAdmin && (
          <Button
            leftIcon={<IconPlus size={14} />}
            onClick={() => navigate('/editcompetition')}
          >
            Create New
          </Button>
        )}
      </Group>

      <SimpleGrid
        cols={4}
        spacing="lg"
        breakpoints={[
          { maxWidth: 'md', cols: 3, spacing: 'md' },
          { maxWidth: 'sm', cols: 2, spacing: 'sm' },
          { maxWidth: 'xs', cols: 1, spacing: 'sm' },
        ]}
      >
        {filteredCompetitions.map(({ id, name, description, miniature, number_of_agents, programmed_end_at_ts, tags = [] }) => (
          <Card key={id} shadow="sm" padding="lg" radius="md" withBorder onClick={() => handleNavigate(id)} style={{ cursor: 'pointer' }}>
            <Card.Section>
              <AspectRatio ratio={1} sx={{ maxWidth: '100%' }}>
                <Image
                  src={`${process.env.REACT_APP_PREFIX_BACKEND}${miniature}` || '/path/to/default/image.png'}
                  alt={name}
                  fit="cover"
                />
              </AspectRatio>
            </Card.Section>

            <Text weight={500} size="lg" mt="md">{name}</Text>

            <Text size="sm" color="dimmed" mt="xs" lineClamp={2}>
              {description}
            </Text>

            <Group spacing={5} mt="sm">
              {tags.slice(0, 3).map((tag, index) => (
                <Badge key={index} variant="light" size="sm">
                  {tag}
                </Badge>
              ))}
              {tags.length > 3 && (
                <Badge variant="light" size="sm">+{tags.length - 3}</Badge>
              )}
            </Group>

            <Group spacing="xs" mt="md">
              <Group spacing={4}>
                <IconUsers size={16} />
                <Text size="sm">{number_of_agents || 'N/A'}</Text>
              </Group>
              <Group spacing={4}>
                <IconClock size={16} />
                <Text size="sm">{getTimeRemaining(programmed_end_at_ts)}</Text>
              </Group>
            </Group>
          </Card>
        ))}
      </SimpleGrid>
    </Container>
  );
};

export default ListCompetition;