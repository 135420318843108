import React, { useState } from 'react';
import { Card, Group, Text, Stack, Modal, List, ThemeIcon, Button, Tooltip } from '@mantine/core';
import { IconTrash, IconFileText, IconEdit, IconDeviceFloppy, IconUpload } from '@tabler/icons-react';
import { Editor } from '@monaco-editor/react';
import { Dropzone } from '@mantine/dropzone';

const FileManagement = ({
  files = [],
  loading = false,
  disabled = false,
  onDelete,
  onEdit,
  onUpload,
  agentCode,
  setagentCode,
}) => {
  const [editMode, setEditMode] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [selectedFile, setSelectedFile] = useState('');

  const editorOptions = {
    minimap: { enabled: false },
    lineNumbers: 'on',
    readOnly: disabled || loading || !editMode,
    automaticLayout: true,
  };

  const handleCodeChange = (newValue) => {
    setagentCode(newValue);
  };

  const toggleEditMode = () => {
    if (editMode) {
      // Save changes by calling onEdit with both filename and content
      onEdit('agent.py', agentCode);
    }
    setEditMode(!editMode);
  };

  const confirmDelete = (file) => {
    setSelectedFile(file);
    setShowConfirmModal(true);
  };

  const deleteFile = () => {
    onDelete(selectedFile);
    setShowConfirmModal(false);
  };

  const handleDrop = (droppedFiles) => {
    droppedFiles.forEach(file => onUpload(file));
  };

  const handleFileSelect = (event) => {
    const selectedFiles = Array.from(event.target.files || []);
    selectedFiles.forEach(file => onUpload(file));
  };

  return (
    <Stack spacing="md">
      {/* Main Upload Area */}
      <Card withBorder>
        <Stack spacing="md">
          <Group position="apart">
            <Text weight={500}>Upload Files</Text>
            <Tooltip label="Select Files">
              <Button
                component="label"
                leftIcon={<IconUpload size={16} />}
                disabled={disabled || loading}
                variant="light"
              >
                Browse Files
                <input
                  type="file"
                  onChange={handleFileSelect}
                  multiple
                  style={{ display: 'none' }}
                  disabled={disabled || loading}
                />
              </Button>
            </Tooltip>
          </Group>

          <Dropzone
            onDrop={handleDrop}
            onReject={(files) => console.log('rejected files', files)}
            maxSize={50 * 1024 ** 2}
            accept={[]} // Accept all file types
            disabled={loading || disabled}
            styles={(theme) => ({
              root: {
                borderColor: theme.colorScheme === 'dark' ? theme.colors.dark[4] : theme.colors.gray[4],
                backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.colors.gray[0],
              },
            })}
          >
            <Group position="center" spacing="xl" style={{ minHeight: 80, pointerEvents: 'none' }}>
              <div style={{ textAlign: 'center' }}>
                <Text size="md" inline>
                  Drag files here or click to select
                </Text>
                <Text size="sm" color="dimmed" inline mt={7}>
                  Upload your model files and any additional resources
                </Text>
              </div>
            </Group>
          </Dropzone>
        </Stack>
      </Card>

      {/* File List */}
      {files.length > 0 && (
        <Card withBorder>
          <Text weight={500} mb="md">Uploaded Files</Text>
          <List spacing="xs">
            {files.map((file) => (
              <List.Item
                key={file}
                icon={
                  <ThemeIcon color={file === 'agent.py' ? 'blue' : 'gray'} size={24} radius="xl">
                    <IconFileText size={16} />
                  </ThemeIcon>
                }
              >
                <Group position="apart" noWrap>
                  <Text size="sm">
                    {file}
                    {file === 'agent.py' && ' (Main File)'}
                  </Text>
                  <Group spacing={8} noWrap>
                    {file === 'agent.py' && (
                      <Tooltip label={editMode ? 'Save Changes' : 'Edit Code'}>
                        <Button
                          variant="light"
                          size="xs"
                          color="blue"
                          onClick={toggleEditMode}
                          disabled={disabled || loading}
                        >
                          {editMode ? <IconDeviceFloppy size={14} /> : <IconEdit size={14} />}
                        </Button>
                      </Tooltip>
                    )}
                    <Tooltip label="Delete File">
                      <Button
                        variant="light"
                        size="xs"
                        color="red"
                        onClick={() => confirmDelete(file)}
                        disabled={disabled || loading}
                      >
                        <IconTrash size={14} />
                      </Button>
                    </Tooltip>
                  </Group>
                </Group>
              </List.Item>
            ))}
          </List>
        </Card>
      )}

      {/* Code Editor */}
      {(agentCode || editMode) && (
        <Card withBorder>
          <Group position="apart" mb="md">
            <Text weight={500}>Agent Code</Text>
            <Button
              variant="light"
              onClick={toggleEditMode}
              disabled={disabled || loading}
              leftIcon={editMode ? <IconDeviceFloppy size={14} /> : <IconEdit size={14} />}
            >
              {editMode ? 'Save Changes' : 'Edit Code'}
            </Button>
          </Group>
          <Editor
            height="400px"
            language="python"
            theme="vs-dark"
            value={agentCode}
            options={editorOptions}
            onChange={handleCodeChange}
          />
        </Card>
      )}

      {/* Delete Confirmation Modal */}
      <Modal
        opened={showConfirmModal}
        onClose={() => setShowConfirmModal(false)}
        title="Confirm Delete"
        size="sm"
      >
        <Stack>
          <Text>Are you sure you want to delete "{selectedFile}"?</Text>
          <Group position="right">
            <Button variant="light" onClick={() => setShowConfirmModal(false)}>
              Cancel
            </Button>
            <Button color="red" onClick={deleteFile}>
              Delete
            </Button>
          </Group>
        </Stack>
      </Modal>
    </Stack>
  );
};

export default FileManagement;