import React, { useState } from 'react';
import { Paper, Title, Stack, Group, Text, Loader, Modal } from '@mantine/core';
import { useRuntimeOptions } from 'hooks/DirectAgentAttach/useRuntimeOptions';
import { useAgentFileManagement } from 'hooks/DirectAgentAttach/useAgentFileManagement';
import RuntimeView from 'components/DirectAgentAttach/RuntimeView';
import { Editor } from '@monaco-editor/react';
import { IconFileText, IconEye, IconDownload } from '@tabler/icons-react';
import { List, ThemeIcon, Button, Tooltip } from '@mantine/core';

const Configuration = ({ 
  competitionId, 
  attacheAgentId
}) => {
  const [showCodeModal, setShowCodeModal] = useState(false);
  
  // Initialize hooks
  const { 
    options: runtimeOptions, 
    selectedRuntime,
    isLoading: isLoadingRuntime,
    error: runtimeError 
  } = useRuntimeOptions(competitionId, false, attacheAgentId);
  const {
    fileState: { files, agentCode, isLoading: isLoadingFiles },
    handlers
  } = useAgentFileManagement(competitionId, attacheAgentId);

  if (isLoadingRuntime) {
    return (
      <Group position="center" p="xl">
        <Loader />
        <Text>Loading configuration...</Text>
      </Group>
    );
  }

  if (runtimeError) {
    return (
      <Text color="red" align="center" p="xl">
        {runtimeError}
      </Text>
    );
  }

  return (
    <Stack spacing="md">
      {/* Runtime Configuration */}
      <Paper shadow="xs" p="md">
        <Stack spacing="md">
          <Title order={3}>Runtime Configuration</Title>
          <RuntimeView runtime={runtimeOptions} />
        </Stack>
      </Paper>

      {/* Files List */}
      <Paper shadow="xs" p="md">
        <Stack spacing="md">
          <Title order={3}>Files</Title>
          
          <List spacing="xs">
            {files?.map((file) => (
              <List.Item
                key={file}
                icon={
                  <ThemeIcon 
                    color={file === 'agent.py' ? 'blue' : 'gray'} 
                    size={24} 
                    radius="xl"
                  >
                    <IconFileText size={16} />
                  </ThemeIcon>
                }
              >
                <Group position="apart" noWrap>
                  <Text size="sm">
                    {file}
                    {file === 'agent.py' && ' (Main File)'}
                  </Text>

                  <Group spacing={8} noWrap>
                    {file === 'agent.py' && (
                      <Tooltip label="View Code">
                        <Button
                          variant="light"
                          size="xs"
                          color="blue"
                          onClick={() => setShowCodeModal(true)}
                        >
                          <IconEye size={14} />
                        </Button>
                      </Tooltip>
                    )}
                    <Tooltip label="Download File">
                      <Button
                        variant="light"
                        size="xs"
                        color="blue"
                        onClick={() => handlers.handleDownload(file)}
                      >
                        <IconDownload size={14} />
                      </Button>
                    </Tooltip>
                  </Group>
                </Group>
              </List.Item>
            ))}
          </List>
        </Stack>
      </Paper>

      {/* Code View Modal */}
      <Modal
        opened={showCodeModal}
        onClose={() => setShowCodeModal(false)}
        title="agent.py"
        size="xl"
      >
        <Editor
          height="500px"
          language="python"
          theme="vs-dark"
          value={agentCode}
          options={{
            readOnly: true,
            minimap: { enabled: false },
            lineNumbers: 'on',
            automaticLayout: true,
          }}
        />
      </Modal>
    </Stack>
  );
};

export default Configuration;