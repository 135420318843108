import React from 'react';
import { Stack, Tabs, Group, Text, Box } from '@mantine/core';
import AgentResultsDashboard from 'components/DirectAgentAttach/Monitor/AgentResultsDashboard';
import AllocationControl from 'components/DirectAgentAttach/Monitor/AllocationControl';
import Configuration from 'components/DirectAgentAttach/Monitor/Configuration';
import AgentResultOverview from 'components/DirectAgentAttach/Monitor/AgentResultOverview';
import LeaderboardTab from 'pages/Competition/LeaderboardTab';
// import { useAgentMonitor } from 'hooks/DirectAgentAttach/useAgentMonitor';


const AgentMonitor = ({competitionId, attacheAgentId, status, onStatusChange
}) => {
  return (
    <Stack spacing="md">
      <Group position="apart" align="flex-start" noWrap>
        <Box ml="auto">
          <AllocationControl
            attacheAgentId={attacheAgentId}
            isStarted={status==='active'}
            handleStart={onStatusChange} 
            isStartBlocked={false} 
          />
        </Box>
      </Group>

      <Tabs defaultValue="dashboard">
        <Tabs.List>
          <Tabs.Tab value="dashboard">Dashboard</Tabs.Tab>
          <Tabs.Tab value="leaderboard">Leaderboard</Tabs.Tab>
          <Tabs.Tab value="configuration">Configuration</Tabs.Tab>
        </Tabs.List>

        <Box mt="md">
          <Tabs.Panel value="dashboard">
            <AgentResultOverview competitionId={competitionId} attacheAgentId={attacheAgentId} />
            <AgentResultsDashboard competitionId={competitionId} attacheAgentId={attacheAgentId} />
          </Tabs.Panel>
          <Tabs.Panel value="leaderboard">
            <LeaderboardTab competitionId={competitionId} />
          </Tabs.Panel>
          <Tabs.Panel value="configuration">
            <Configuration competitionId={competitionId} attacheAgentId={attacheAgentId} />
          </Tabs.Panel>
        </Box>
      </Tabs>
    </Stack>
  );
};

export default AgentMonitor;