import React, { useEffect, useState } from 'react';
import { ScrollArea, Stack, Group, Text, Badge, Divider } from '@mantine/core';
import { formatDistanceToNow } from 'date-fns';
import { User, PlayCircle, History } from 'lucide-react';

const VideoFileList = ({ socket_current, competitionId, onSelectVideo, selectedVideo }) => {
  const [runs, setRuns] = useState({});

  useEffect(() => {
    if (!socket_current) return;

    socket_current.emit("request_file_list");
    socket_current.on("file_list_update", (data) => {
      setRuns(data.files);
    });

    return () => {
      socket_current.off("file_list_update");
    };
  }, [socket_current]);

  useEffect(() => {
    if (selectedVideo && selectedVideo[0] === null && Object.keys(runs).length > 0) {
      onSelectVideo([Object.keys(runs)[0], Object.values(runs)[0]]);
    }
  }, [selectedVideo, runs, onSelectVideo]);

  const formatTimestamp = (timestamp) => {
    const date = new Date(
      timestamp.slice(0, 4),
      timestamp.slice(4, 6) - 1,
      timestamp.slice(6, 8),
      timestamp.slice(8, 10),
      timestamp.slice(10, 12)
    );
    return formatDistanceToNow(date, { addSuffix: true });
  };

  const ScoreIndicator = ({ score }) => (
    <Text 
      size="sm" 
      fw={700} 
      className={score > 0 ? 'text-green-600' : 'text-red-600'}
    >
      {score.toFixed(2)}
    </Text>
  );

  return (
    <div style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
      <Group spacing="xs" px="md" py="sm" noWrap>
        <History size={16} className="text-gray-500" />
        <Text size="md" fw={600} className="text-gray-700">
          Match History
        </Text>
      </Group>

      <ScrollArea style={{ flex: 1 }} type="auto" offsetScrollbars>
        <Stack spacing={0} px="md" pb="md">
          {Object.entries(runs).map(([fileName, fileData], index, array) => {
            const timeKey = fileName.split(".")[0];
            const formattedTime = formatTimestamp(timeKey);
            const players = Object.values(fileData);
            const isSelected = selectedVideo && selectedVideo[0] === fileName;

            return (
              <React.Fragment key={fileName}>
                <div
                  className={`
                    cursor-pointer 
                    transition-all 
                    duration-200 
                    relative 
                    p-3
                    rounded-md
                    border
                    ${isSelected ? 
                      'bg-blue-50 border-l-4 border-l-blue-500 shadow-sm' : 
                      'hover:bg-gray-50 border-l-4 border-l-transparent'
                    }
                  `}
                  onClick={() => onSelectVideo([fileName, fileData])}
                >
                  {isSelected && (
                    <div className="absolute left-2 top-1/2 -translate-y-1/2">
                      <PlayCircle size={16} className="text-blue-500" />
                    </div>
                  )}
                  <Stack spacing="xs" className={isSelected ? 'pl-6' : ''}>
                    <Group position="apart" noWrap>
                      <Badge 
                        size="sm" 
                        variant={isSelected ? "filled" : "light"}
                        color={isSelected ? "blue" : "gray"}
                      >
                        {formattedTime.toUpperCase()}
                      </Badge>
                    </Group>

                    {players.map((player, idx) => (
                      <Group key={idx} position="apart" noWrap className="w-full">
                        <Group spacing="xs" noWrap className="flex-1">
                          <User size={16} className={isSelected ? "text-blue-500" : "text-gray-500"} />
                          <Stack spacing={0}>
                            <Text 
                              size="sm" 
                              fw={isSelected ? 600 : 500} 
                              lineClamp={1}
                              className={isSelected ? "text-blue-900" : ""}
                            >
                              {player.agent_name}
                            </Text>
                            <Text 
                              size="xs" 
                              color={isSelected ? "blue.7" : "dimmed"} 
                              lineClamp={1}
                            >
                              {player.user_name}
                            </Text>
                          </Stack>
                        </Group>
                        <ScoreIndicator score={player.cum_reward} />
                      </Group>
                    ))}
                  </Stack>
                </div>
                {index < array.length - 1 && (
                  <Divider my="sm" variant="dotted" />
                )}
              </React.Fragment>
            );
          })}
        </Stack>
      </ScrollArea>
    </div>
  );
};

export default VideoFileList;