import React from 'react';
import { Container, Tabs, Group, Text, Stack, Alert, Paper, Divider } from '@mantine/core';
import { AlertCircle, Upload, TestTube2, Flag, Check, X } from 'lucide-react';

const ProgressSteps = ({ status }) => {
  // Get step state based on agent status
  const getStepState = (phase) => {
    switch (phase) {
      case 'upload':
        return {
          step: 0,
          icon: status === 'upload_validated' ? <Check className="w-6 h-6" /> : 
                status === 'upload_failed' ? <X className="w-6 h-6" /> :
                <Upload className="w-6 h-6" />,
          color: status === 'upload_validated' ? 'green' :
                 status === 'upload_failed' ? 'red' : 'blue'
        };
      case 'deploy':
        return {
          step: 1,
          icon: status === 'active' ? <Check className="w-6 h-6" /> :
                status === 'deploy_failed' ? <X className="w-6 h-6" /> :
                <TestTube2 className="w-6 h-6" />,
          color: status === 'active' ? 'green' :
                 status === 'deploy_failed' ? 'red' : 'blue'
        };
      case 'monitor':
        return {
          step: 2,
          icon: <Flag className="w-6 h-6" />,
          color: status === 'active' ? 'green' : 'blue'
        };
      default:
        return { step: 0, icon: <Upload className="w-6 h-6" />, color: 'blue' };
    }
  };

  const steps = [
    { label: 'Upload', description: 'Upload agent code', phase: 'upload' },
    { label: 'Deploy', description: 'Test and deploy agent', phase: 'deploy' },
    { label: 'Monitor', description: 'Monitor performance', phase: 'monitor' }
  ];

  const activeStep = Math.max(
    status === 'active' ? 2 :
    ['deploying', 'deploy_failed'].includes(status) ? 1 :
    ['upload_validated', 'upload_failed'].includes(status) ? 1 : 0
  );

  return (
    <Paper p="md" radius="md" className="mb-6">
      <Group grow className="relative">
        {steps.map((step, index) => {
          const { icon, color } = getStepState(step.phase);
          return (
            <Stack key={step.phase} spacing={4} align="center" className="relative">
              {index > 0 && (
                <Divider 
                  className="absolute top-6 -left-1/2 w-full" 
                  color={index <= activeStep ? color : 'gray'}
                />
              )}
              <div className={`rounded-full p-2 bg-${color}-100 border-2 border-${color}-500 relative z-10`}>
                {icon}
              </div>
              <Text size="sm" fw={600} c={color}>
                {step.label}
              </Text>
              <Text size="xs" c="dimmed" className="text-center">
                {step.description}
              </Text>
            </Stack>
          );
        })}
      </Group>
    </Paper>
  );
};

export default ProgressSteps;