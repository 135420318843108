import React, { useState, useEffect } from 'react';
import { Container, Card, Text, Group, Button, TextInput, Tooltip, CopyButton, Stack, Title, Divider, ActionIcon } from '@mantine/core';
import { IconCopy, IconKey } from '@tabler/icons-react';
import { notifications } from '@mantine/notifications';

const Profile = () => {
  const [profile, setProfile] = useState({
    username: '',
    email: '',
    created_at_ts: '',
    key_id: '',
    key_pass: '',
  });

  useEffect(() => {
    fetch(`${process.env.REACT_APP_PREFIX_BACKEND}/api/profile/`, {
      method: 'GET',
      credentials: 'include',
    })
      .then((response) => response.json())
      .then((data) => setProfile(data))
      .catch((error) => notifications.show({ message: `Error fetching profile: ${error.message}`, color: 'red' }));
  }, []);

  const generateNewKey = () => {
    fetch(`${process.env.REACT_APP_PREFIX_BACKEND}/api/auth/generate_new_key`, {
      method: 'POST',
      credentials: 'include',
      headers: { 'Content-Type': 'application/json' },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.key_id) {
          setProfile((prevProfile) => ({
            ...prevProfile,
            key_id: data.key_id,
            key_pass: data.key_pass,
          }));
          notifications.show({ message: 'New API key generated successfully', color: 'green' });
        } else {
          notifications.show({ message: 'Failed to generate new API keys', color: 'red' });
        }
      })
      .catch((error) => notifications.show({ message: `Error generating new API keys: ${error.message}`, color: 'red' }));
  };

  const ApiKeyInput = ({ label, value }) => (
    <TextInput
      label={label}
      value={value}
      readOnly
      rightSection={
        <CopyButton value={value} timeout={2000}>
          {({ copied, copy }) => (
            <Tooltip label={copied ? 'Copied' : 'Copy'} withArrow position="right">
              <ActionIcon color={copied ? 'teal' : 'blue'} onClick={copy} size="lg">
                <IconCopy size={20} />
              </ActionIcon>
            </Tooltip>
          )}
        </CopyButton>
      }
    />
  );

  return (
    <Container size="sm" py="xl">
      <Card shadow="sm" p="lg" radius="md" withBorder>
        <Title order={2} mb="md">User Profile</Title>
        <Stack spacing="md">
          <Group grow>
            <TextInput label="Username" value={profile.username} readOnly />
            <TextInput label="Email" value={profile.email} readOnly />
          </Group>
          <TextInput label="Account Created" value={profile.created_at_ts} readOnly />
          
          <Divider my="sm" label="API Access" labelPosition="center" />
          
          {profile.key_id && (
            <Stack spacing="xs">
              <ApiKeyInput label="Key ID" value={profile.key_id} />
              <ApiKeyInput label="Key Pass" value={profile.key_pass} />
            </Stack>
          )}
          
          <Button onClick={generateNewKey}>
            <Group spacing="xs">
              <IconKey size={20} />
              <Text>Generate New API Key</Text>
            </Group>
          </Button>
        </Stack>
      </Card>
    </Container>
  );
};

export default Profile;