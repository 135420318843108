import { useState } from 'react';
import axios from 'axios';

const useAgentAllocation = (attacheAgentId) => {
  const [allocation, setAllocation] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [isUpdating, setIsUpdating] = useState(false);

  // Fetch allocation data
  const fetchAllocation = async () => {
    setIsLoading(true);
    setError(null);
    
    try {
      const response = await axios.get(`/api/attaches/agent/my_agents/${attacheAgentId}/allocation`);
      setAllocation(response.data);
    } catch (err) {
      setError(err.response?.data?.error || 'Failed to fetch allocation data');
      console.error('Error fetching allocation:', err);
    } finally {
      setIsLoading(false);
    }
  };

  // Update allocation
  const updateAllocation = async (newAllocation) => {
    setIsUpdating(true);
    setError(null);
    
    try {
      await axios.put(`/api/attaches/agent/my_agents/${attacheAgentId}/${newAllocation}`);
      // Refresh allocation data after update
      await fetchAllocation();
      return true;
    } catch (err) {
      setError(err.response?.data?.error || 'Failed to update allocation');
      console.error('Error updating allocation:', err);
      return false;
    } finally {
      setIsUpdating(false);
    }
  };

  return {
    // Data
    allocation: allocation?.current_allocation,
    availableAllocation: allocation?.available_allocation,
    totalLimit: allocation?.total_limit,
    
    // Loading states
    isLoading,
    isUpdating,
    
    // Error state
    error,
    
    // Actions
    fetchAllocation,
    updateAllocation,
    
    // Computed helpers
    canIncrease: allocation?.available_allocation > 0,
    canDecrease: allocation?.current_allocation > 0,
  };
};

export default useAgentAllocation;